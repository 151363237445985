import React, { useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '~/_helpers/breakpoints';
import { useHistory, useLocation } from "react-router-dom";
  
const WrappedStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(255, 255, 255, 0.85);
    .inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        max-width: 370px;
        background-color: #FFFFFF;
        padding: 25px;
        border-radius: 12px;
        margin-top: 20px;

        -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
        -ms-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);

        .ico_phone{
            width: 150px;
            img{
                float: left;
                width: 100%;
            }
        }
        .title{
            font-weight: 500;
            text-align: center;
        }
        .description{
            font-size: 14px;
            line-height: 25px;
            text-align: center;
        }
        .actions{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 20px;
            .bt_google,
            .bt_appstore{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: #D80115;
                border: 1px solid #D80115;
                border-radius: 6px;
                padding: 5px;
                width: calc(100% - 40px);
                cursor: pointer;
                .text{
                    font-weight: 500;
                    color: #FFFFFF;
                }
                .ico{
                    width: 25px;
                    margin-left: 5px;
                    img{
                        float: left;
                        width: 100%;
                    }
                }
            }
            .bt_appstore{
                background-color: #FFC600;
                margin-top: 5px;
                border: 1px solid #FFC600;
                .text{
                    color: #000000;
                }
            }
            .bt_notnow{
                cursor: pointer;
                margin-top: 20px;
                font-size: 16px;
                line-height: 16px;
                font-weight: 600;
                color: #D80115;
            }
        }
    }
    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {
        justify-content: center;
        .inner{
            margin-top: 0;
        }
    }
    @media ${breakpoints.md} {
        
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }

`

const PopProfile = (props) => {

    let history = useHistory();

    const { setOpenAppPop } = props;

    const handleGotoStore = (store) => {

        if(store === "google"){
            window.open("https://play.google.com/store/apps/details?id=com.oravian.one.sp", '_blank').focus();
        }

        if(store === "appstore"){
            window.open("https://apps.apple.com/ar/app/somos-protagonistas-2-0/id6467438150", '_blank').focus();
        }

        if(store === "dismiss"){
            setOpenAppPop(false);
        }

    }

    const handleGotoProfile = () => {

        history.push(`/perfil`);
        setOpenAppPop(false);

    }

    return (
        <WrappedStyled>
            <div className='inner'>
                <div className='ico_phone'>
                    <img src='images/ico_popprofile.png' />
                </div>
                <div className='title'>Verificación de datos</div>
                <div className='description'>Por favor completa tu e-mail y/o número de celular para poder validar tus datos al moento de actualizar tus contraseñas. Dirigite a "mi perfil" para hacer los cambios.</div>
                <div className='actions'>
                    {/* <div className='bt_google' onClick={() => handleGotoStore("google")}>
                        <div className='text'>Ir a mi perfil</div>
                        <div className='ico'>
                            <img src='images/ico_googleplay.png' />
                        </div>
                    </div> */}
                    <div className='bt_appstore' onClick={() => handleGotoProfile()}>
                        <div className='text'>Ir a mi perfil</div>
                    </div>
                    <div className='bt_notnow' onClick={() => handleGotoStore("dismiss")}>Cerrar</div>
                </div>
            </div> 
        </WrappedStyled>
    );
};

export default PopProfile;