import axios from 'axios';
import { Auth } from 'aws-amplify';

export const handleError = error => {

};

const _connect = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
  },
});

_connect.interceptors.request.use(
  async (config) => {

    await getToken(config);
    return config;

  },
  (error) => {
    console.error("error", error);
    return Promise.reject(error);
  },
);

_connect.interceptors.response.use(
  (res) => {

    if(res.data.status !== 1){
      return Promise.reject("error");
    }
    return res;
  },
  async (error) => {

    const originalConfig = error.config;
    if (error.response) {
      if (error.response.status === 401) {
        return Promise.reject(error.response.data);
      }

      if (error.response.status !== 401) {
        return Promise.reject(error.response.data);
      }
    }

    return Promise.reject(error);

  }

);

const getToken = async (config) => {

  await Auth.currentSession().then(res=>{
    const idToken = res.getIdToken();
    config.headers.Authorization = idToken.jwtToken;//temp_token;
  })

}

export default _connect;
