import React, { useState, useEffect, useCallback, useReducer, useMemo, useRef } from 'react';
import { path, defaultTo } from 'ramda';
import storage from 'local-storage-fallback';
import _connect from '../_helpers/new_connect';
import axios from 'axios';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { auth_config } from '~/_helpers/commons';

Amplify.configure({
  Auth: auth_config,
  Storage: {
    AWSS3: {
        bucket: 'dotsprojectdev-storage-fcc018c4110821-dev', //REQUIRED -  Amazon S3 bucket
        region: 'us-east-2', //OPTIONAL -  Amazon service region
      }
  }
});
//https://dotsprojectdev-storage-fcc018c4110821-dev.s3.us-east-2.amazonaws.com
export const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {

    const [ isAuth, setIsAuth ] = useState(null);
    const [ authToken, setAuthToken ] = useState(null);
    const [ userData, setUserData ] = useState(null);
    const [ userAmplify, setUserAmplify ] = useState(null);
    const [ isAuthLoading, setIsAuthLoading ] = useState(null);
    const [ activeRoute, setActiveRoute ] = useState('');
    const [ loginError, setLoginError ] = useState(false);
    const [ tttcAgree, setTttcAgree ] = useState(null);
    const [ newPasswordRequired, setNewPasswordRequired ] = useState({});








  // LOGIN NEW VERSION

  const signIn = useCallback(async (username, password) => {
        try {
            setLoginError(false);
            setIsAuthLoading(true);
            const tttc_agree = true;
            //const user = await Auth.signIn("sp_" + username, password);//Auth.signIn(username, password);

            const user = await Auth.signIn("sp_" + username, password)
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                console.log('aca validaa el caso ', user)
                setNewPasswordRequired(user);
                return null;
                //isContinueReg(user)
            } else {
                console.log("user.challengeName ELSE", user)
              //await checkUser()
            }
            //console.log("1")
            setUserAmplify(user);
            //console.log("2")
            get_terms_and_conditions(user);
            //console.log("3")

        } catch (error) {
          //console.log("---> error")
            setLoginError(true);
            setIsAuthLoading(false);
            return {success: false, message: error.message};
        }
    }, []);

    const signOut = useCallback(async () => {
        setIsAuthLoading(true);
        try {

            await Auth.signOut();
            setIsAuthLoading(false);
            setIsAuth(false);
            storage.removeItem('protagonistas_token');

        } catch (error) {

        }
    }, []);

    /*
    const get_terms_and_conditions = useCallback(async (user) => {
      alert("asd");
      const requestURL = `${process.env.REACT_APP_BASE_URL}/get_terms_and_conditions`;
  
      const result = await _connect.get(`${requestURL}`, {
          //headers: {},
      });
      
      console.log("result", result);
      
      try {
  
        const requestURL = `${process.env.REACT_APP_BASE_URL}/get_terms_and_conditions`;
  
        const result = await _connect.get(`${requestURL}`, {
            headers: {},
        });
  
        console.log("result", result);
  
      } catch (error) {
        
        console.log("error", error)
        //logout();
        return false;
  
      }
      
  
    }, []);
    */
    

    const get_terms_and_conditions = (user) => {
      /*
      const tttc_agree_status = true;//!path(['data', 'single', 'status'], res);
      setTttcAgree(tttc_agree_status);
      storage.setItem('protagonistas_tttc_agree', tttc_agree_status);
      storage.setItem('protagonistas_token', user.signInUserSession.idToken.jwtToken);
      storage.setItem('protagonistas_access_token', user.signInUserSession.accessToken.jwtToken);
      setIsAuthLoading(false);
      setIsAuth(true);
      */
      //console.log("4")

      
      const requestURL = `${process.env.REACT_APP_BASE_URL}/get_terms_and_conditions`;
      const response = _connect.get(`${requestURL}`);

      response.then(res => {
        // console.log("5", res)
        
        const tttc_agree_status = false;//path(['data', 'status'], res);//true;//!path(['data', 'single', 'status'], res);
        setTttcAgree(tttc_agree_status);
        //setTttcAgree(tttc_agree_status ? true : false);
        storage.setItem('protagonistas_tttc_agree', tttc_agree_status ? true : false);
        storage.setItem('protagonistas_token', user.signInUserSession.idToken.jwtToken);
        storage.setItem('protagonistas_access_token', user.signInUserSession.accessToken.jwtToken);
        setIsAuthLoading(false);
        setIsAuth(true);
        
      }).catch(err => {

        const tttc_agree_status = true;//path(['data', 'status'], res);//true;//!path(['data', 'single', 'status'], res);
        setTttcAgree(tttc_agree_status);
        //setTttcAgree(tttc_agree_status ? true : false);
        storage.setItem('protagonistas_tttc_agree', tttc_agree_status ? true : false);
        storage.setItem('protagonistas_token', user.signInUserSession.idToken.jwtToken);
        storage.setItem('protagonistas_access_token', user.signInUserSession.accessToken.jwtToken);
        setIsAuthLoading(false);
        setIsAuth(true);

        //console.log("6", err)
        //setHaveResponseError(true);
        //setHaveResponseErrorMessage(err.message);

      });
      

    };
    


    const setAgreeTerms = async (payload) => {

      const requestURL = `${process.env.REACT_APP_BASE_URL}/accept_terms_and_conditions`;//oneapp/users/tttc_agree
      const params = {};//new URLSearchParams()
      /*
      if(Object.keys(payload).length){
          Object.keys(payload).map(function(key) {
              params.append(key, payload[key]);
          })
      }
      */
      const config = {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          }
      }
      const result = await _connect.post(requestURL, params, config);
      return result;
    }

    // const setAgreeTerms = async () => {

    //   const requestURL = `${process.env.REACT_APP_API_SERVER}/users/tttc_agree`;

    //   try {

    //     const token = storage.getItem('protagonistas_token');

    //     const params = new URLSearchParams()
    //           params.append('token', token);

    //     const config = {
    //       headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //       }
    //     }

    //     const result = await connect.post(requestURL, params, config);

    //     return result;

    //   } catch (error) {
    //   }




    // }




    useEffect(() => {



      const actualToken = defaultTo('', storage.getItem('protagonistas_token'));
      setAuthToken(actualToken);
      if(actualToken.length > 0){
        setIsAuth(true);
        setTttcAgree(storage.getItem('protagonistas_tttc_agree'));
      }
    }, []);

    useEffect(() => {

      if (!authToken) {
        //updateUserLoginData({ ...loggedOutUser });
      } else {
        //updateToken(authToken);
        //fetchData();
      }
        /*
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('utm_source') === 'mobile_app') {
        setIsMobileApp(true);
        }
        */

        //setAuthToken(defaultTo('', storage.getItem('ie_access_token')));

    }, [authToken]);

    const login = useCallback(

        async (username, password) => {
          setIsAuthLoading(true);
          setLoginError(false);

          try {
            const payload = {
              password: password,
              e_mail: username,
            };

            const params = new URLSearchParams()
                  params.append('password', password)
                  params.append('e_mail', username)
                  params.append('id_client', '6139135c3814168c11fa57ee')
                  params.append('firebase_token', '')
                  params.append('version_name', '1.0')
                  params.append('version_code', 'web')

                  const config = {
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded'
                    }
                  }

              _connect.post(`${process.env.REACT_APP_API_SERVER}/login`, params, config).then(response => {
                const status = path(['data', 'status'], response);
                if(status === 1){
                  const accessToken = path(['data', 'single', 'token'], response);
                  const tttc_agree = path(['data', 'single', 'tttc_agree'], response);
                  const embajadores = (path(['data', 'single', 'flag_user'], response)) == 3 ? true :  false;

                  setTttcAgree(tttc_agree);
                  setIsAuth(true);
                  storage.setItem('protagonistas_tttc_agree', tttc_agree);
                  storage.setItem('protagonistas_token', accessToken);
                  storage.setItem('show_embajadores', embajadores);
                  storage.setItem('popup_shellbox2_puntosextra', false);

                  setActiveRoute('');
                }else{
                  setLoginError(true);
                }

                setIsAuthLoading(false);

              })

            return true;

        } catch (e) {


        }

        return false;

        },[],
    );

    const logout = useCallback(async () => {
      storage.clear();
      setAuthToken(null);
      setIsAuth(false);
    }, [authToken]);

    const recupero = useCallback(async (email, dni) => {
    }, []);


    const getPhoneToValidate = async (dni) => {

      try {

        const response = await _connect.get(`${process.env.REACT_APP_API_SERVER}/users/get_phone_to_validated`, {
          headers: {
            'employee_id': dni,
            'id_client': '6139135c3814168c11fa57ee',
          },
        });

        const response_parse = path(['data', 'single'], response);

        return response_parse;

        /*
        if(posts && posts.length){
          setPosts(prevState => [...prevState, ...normalized]);
        }else{
          setPosts(normalized);
        }
        */


      } catch (error) {
        //alert("logout");
        //setLoading(false);
        //console.error(error);
      }
    };

    const changePasswordRecover = async (payload) => {

      const requestURL = `${process.env.REACT_APP_API_SERVER}/users/change_password_recover`;

      try {

        const token = storage.getItem('protagonistas_token');

        const params = new URLSearchParams()
              params.append('employee_id', payload.dni);
              params.append('new_password', payload.new_password);
              params.append('re_password', payload.re_password);
              params.append('id_client', '6139135c3814168c11fa57ee');

        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }

        const result = await _connect.post(requestURL, params, config);

        return result;

      } catch (error) {
        //alert("logout");
        //logout();
        //console.error(error);
      }

    }





  return (
    <AuthContext.Provider
      value={{
        Auth,
        isAuth,
        userData,
        isAuthLoading,
        setIsAuthLoading,
        login,
        logout,
        activeRoute,
        setActiveRoute,
        loginError,
        setLoginError,
        getPhoneToValidate,
        changePasswordRecover,
        setAgreeTerms,
        tttcAgree, setTttcAgree,
        signIn, signOut,
        newPasswordRequired, 
        setNewPasswordRequired
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
