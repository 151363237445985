import React, { useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  Fragment } from 'react';

import dayjs from 'dayjs';
import storage from 'local-storage-fallback';
import { breakpoints } from '../../_helpers/breakpoints';
import ReactCrop from 'react-image-crop';
import styled from 'styled-components';
import TermsText from '../../_components/Maintenance/TermsText';
import { ButtonYellow } from '../../_elements';
import { RiPencilFill } from "react-icons/ri";
import { IoCloudUploadOutline } from "react-icons/io5";
import { RiCloseCircleLine } from "react-icons/ri";
import { TbFileCertificate } from "react-icons/tb";
import { Spinner } from '../../_helpers/constants';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.scss';
import { Navigation, Pagination } from 'swiper';
import { FaEdit, FaChevronLeft, FaChevronRight, FaTimes } from "react-icons/fa";

import { useHistory, useLocation } from "react-router-dom";
import Countdown, { zeroPad } from 'react-countdown';
import CircularProgress from '@material-ui/core/CircularProgress';
import { downloadFileFromUrl,
validateEmail,
fixRolName } from '../../_helpers/commons';

import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';

import { FaCheck } from "react-icons/fa6";

import { COLORS } from '../../_helpers/constants';
import ChangePassword from '../../_components/Profile/ChangePassword';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';
import DateFnsUtils from '@date-io/moment'; // choose your lib
import { ContentContext, AuthContext } from '../../_context';
import "moment/locale/es";
import 'react-image-crop/dist/ReactCrop.css';
moment.locale('es');




const Wrapper = styled.div`

  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  //padding-top: 15px;
  padding-bottom: 15px;
  //border-bottom: 3px solid #FFFFFF;
  //cursor: pointer;
  *input{
    :focus{
      outline: none;
    }
  }
  
  .field_data_container .verificado,
  .field_data_container .sin_verificado{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    .icon{
      display: flex;
      font-size: 17px;
      line-height: 17px;
      color: green;
    }
    p{
      padding: 0;
      margin: 0;
      margin-left: 5px;
      font-weight: 600;
      font-size: 14px;
      color: green;
    }
  }
  .field_data_container .sin_verificado{
    .icon{
      color: red;
    }
    p{
      color: red;
    }
  }
`

const KeyboardDatePickerStyled = styled(KeyboardDatePicker)`
  .MuiInputBase-root{

    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    outline: none;
    ::before{
      border: none !important;
    }

    .MuiIconButton-root{
      outline: none;
      padding-left: 20px;
      padding-right: 20px;
      padding: 0;
      background: none !important;
      .MuiSvgIcon-root{
        font-size: 30px;
        padding-right: 5px;
        fill: #2B2C2D!important;
      }
      :hover{
        //color: #000000;
        background: none !important;
        .MuiSvgIcon-root{
            fill: #FFD200!important;
        }
      }
      .MuiTouchRipple-root{
        display: none;
      }
    }

    .MuiInputAdornment-root{
      width: 40px;
    }

  }



  input{
    display: flex;
    width: calc(100% - 26px);
    background-color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    color: #7884A5;
    border: 0!important;
    border-radius: 5px;
    height: 26px;
    outline: none;
    :focus {
      outline: none;
    }
    *:focus {
      outline: none;
    }
  }

  .MuiFormLabel-root{
    font-size: 15px!important;
    font-weight: 600;
    color: #333333;
    margin-bottom: 4px;
  };
`
const ProfileDetail = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .profile_data{
    width: 100%;
    display: flex;
    flex-direction: column;

    .profile_image{
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #CCCCCC;
      width: 80px;
      height: 80px;
      border-radius: 40px;
      overflow: hidden;
      position: relative;

      .button_edit_image{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #FFD200;
        width: 23px;
        height: 23px;
        border: 1px solid #FFD200;
        position: absolute;
        z-index: 2;
        :hover{
          color: #FFD200;
          background-color: #FFFFFF;
        }
      }
      .image{
        img{
          width: 100%;
        }
      }
    }
    .profile_name{
      width: calc(100% - 95px);
      margin-left: 15px;
    }

    .profile_data_header{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
    }
    .field_data_container{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;
      .field_data{
        padding: 10px;
        padding-left: 20px;
        background-color: #F5F6FA;
        //background-color: ${COLORS.secondary};
      }
    }
    .field_data{
      display: flex;
      flex-direction: column;
      margin-bottom: 2px;
      //margin-right: 15px;
      p.label{
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        // remover color
        color: ${COLORS.primary};
      }
      p.value{
        margin: 0;
        font-size: 18px;
        font-weight: 400;
        // remover color
        color: ${COLORS.primary};
      }
      &.name{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 0;
        p.value{
          font-size: 7vw;
          line-height: 8vw;
          font-weight: 400;
        }
      }
      &.rol_puesto{
        margin-bottom: 0;
        margin-top: 5px;
        p.value{
          font-size: 13px!important;
          font-weight: 600;
          line-height: 13px;
          color: #D8010F;
        }
      }
      &.estation{
        margin-top: 5px;
        p.value{
          font-weight: 600;
          font-size: 13px!important;
          //color: #333333;
          //remover color
          color: ${COLORS.primary};
        }
      }

      .button_edit{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #FFD200;
        width: 23px;
        height: 23px;
        border: 1px solid #FFD200;
        margin-bottom: 7px;
        margin-left: 10px;
        :hover{
          color: #FFD200;
          background-color: #FFFFFF;
        }
      }
    }
  }
@media ${breakpoints.xs} {

  .profile_data{
    width: 100%;

    .profile_image{
      width: 80px;
      height: 80px;
      border-radius: 40px;

      .button_edit_image{
        width: 23px;
        height: 23px;
      }
    }
    .profile_name{
      margin-left: 15px;
    }
    .profile_data_header{
      //align-items: center;
      margin-bottom: 20px;
    }


    .field_data{
      margin-bottom: 2px;
      //margin-right: 15px;
      p.label{
        margin: 0;
        font-size: 14px;
      }
      p.value{
        margin: 0;
        font-size: 18px;
      }
      &.name{
        margin-bottom: 0;
        p.value{
          font-size: 38px;
          line-height: 40px;
          font-weight: 400;
        }
      }
      &.rol_puesto{
        margin-bottom: 0;
        margin-top: 5px;
        p.value{
          font-size: 13px!important;
          font-weight: 600;
          line-height: 13px;
        }
      }
      &.estation{
        margin-top: 5px;
        p.value{
          font-weight: 600;
          font-size: 13px!important;
        }
      }

      .button_edit{
        width: 23px;
        height: 23px;
        margin-bottom: 7px;
        margin-left: 10px;
      }
    }
  }
}
@media ${breakpoints.sm} {

}
@media ${breakpoints.md} {

}
@media ${breakpoints.lg} {

}
`
const ModalImageProfileStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0!important;
  border-radius: 16px;

  :focus{
    outline: none!important;
  }

  position: absolute;
  top: 4vw!important;
  overflow: scroll;
  margin: 0 auto;
  width: 95vw;
  height: 100%;
  display: block;

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {
    top: 20px!important;
    width: 600px;
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`
const ContainerModalProfileImage = styled.div`
  position: relative;
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 16px;
  outline: 0!important;
  -webkit-box-shadow: 0px 0px 15px -5px #000000;
  box-shadow: 0px 0px 15px -5px #000000;

  .modal_block{
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 99;
    border-radius: 16px;
    svg{
      color: #FFD200!important;
    }
  }
  .actions{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .bt_save{
      width: calc(50% - 3px)!important;
    }
    .bt_cancel{
      width: calc(50% - 3px)!important;
    }
  }
  .content_action_browse{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //border: 1px dashed #CCCCCC;
    margin-top: 0px;
    //padding-top: 40px;
    //padding-bottom: 40px;
    input{
      display: none;
    }
  }
  .modal_imageprofile{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    .modal_imageprofile_container{
      display: flex;
      flex-direction: column;
      width: 100%;
      .modal_imageprofile_image{
        display: flex;
        width: 100%;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 10px;
      }
    }
  }
`
const Terms = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .bt_download_terms{
    margin-top: 10px;
  }
  .title{
    display: flex;
    width: 100%;
    p{
      padding-left: 20px;
      margin: 0;
      margin-bottom: 15px;
      font-size: 25px;
      font-weight: 500;
      //remover color
      color: ${COLORS.primary}
    }
  }
  .text_terms{
    display: flex;
    background-color: #F5F6FA;
    //background-color: ${COLORS.secondary};
    height: 230px;

    .inner_terms{
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-top: 20px;
      display: flex;
      height: 190px;
      overflow: hidden;
      overflow-y: auto;
      p{
        display: flex;
        //margin: 0;
        font-size: 14px;
        padding-right: 15px;
        //remover color
        color: ${COLORS.primary};
      }
    }
  }
`
const ButtonBrowseProfileImage = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100%;
  border: 1px dashed #CCCCCC;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 10px;
  .icon{
    display: flex;

    svg{
      width: 50px;
      height: 50px;
      color: #333333;
    }
  }
  :hover{
    background-color: #F5F6FA;
    border: 1px dashed #FFD200;
    .icon{
      svg{
      color: #FFD200;
      }
    }
  }
`
const ContainerPassword = styled.div`
  width: 100%;
  margin-top: 20px;

  .innerContainerPassword{
    background-color: #F5F6FA;
    //background-color: ${COLORS.secondary};
    padding: 10px 20px 20px 20px;
    label{
      color: ${COLORS.primary};
    }
  }
  .title{
    display: flex;
    width: 100%;
    p{
      padding-left: 20px;
      margin: 0;
      margin-bottom: 15px;
      font-size: 25px;
      font-weight: 500;
      //remover color
      color: ${COLORS.primary}
    }
  }
`
const ButtonClose = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  cursor: pointer;
  right: 20px;
  top: 20px;
  z-index: 9;
  &.bt_close_modal_cog{
    z-index: 9999;
  }
  .icon{
    display: flex;
    font-size: 45px;
    color: #FFD200;
  }
  :hover{
    .icon{
      color: #000000;
    }
  }
`
const ModalStyledForm = styled(Modal)`

  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0;
  position: relative;
  overflow: auto;
  z-index: 9999!important;

  width: 100%;


  .inner_modal_certificates{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    min-height: 100vh;
    padding: 15px;
    background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 0;
    margin-bottom: 0;

    .box_edit_phone,
    .box_edit_email{
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.95);
      z-index: 999;
      border-radius: 12px;
      .error_message{
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        color: red;
        margin-top: 15px;
      }
      .inner{
        width: calc(100% - 100px);
      }
      .new_phone_actions,
      .new_email_actions,
      .code_phone_actions,
      .code_email_actions{
        margin-top: 15px;
        
        .loader{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40px;
          margin-top: 0px!important;
          svg{
            color: #FFD200;
          }
        }
        .virified{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          line-height: 16px;
          font-weight: 600;
          color: #FFD200;
          height: 40px;
          /* margin-top: 10px; */
          border: 1px dashed #FFD200;
        }

      }
    }
    .form{
      width: 90vw;
      //background-color: pink;
      padding-bottom: 30px;
      .verificado,
      .sin_verificado{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
        .icon{
          display: flex;
          font-size: 17px;
          line-height: 17px;
          color: green;
        }
        p{
          padding: 0;
          margin: 0;
          margin-left: 5px;
          font-weight: 600;
          font-size: 14px;
          color: green;
        }
      }
      .sin_verificado{
        .icon{
          color: red;
        }
        p{
          color: red;
        }
      }
      .modal_profile_edit_title{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 5vw;
        margin-top: 5vw;
        .modal_profile_edit_icon{
          font-size: 6vw;
        }
        p{
          margin: 0;
          margin-left: 1vw;
          font-size: 8.5vw;
          line-height: 8.5vw;
          font-weight: 400;
        }
      }


      .field{
        display: flex;
        flex-direction: column;
        margin-bottom: 3vw;
        .countdown{
          display: flex;
          flex-direction: row;
          padding-top: 5px;
          margin-left: 5px;
          font-weight: 600;
          font-size: 14px;

          .count_value{
            margin-left: 5px;
          }
          .bt_reintentar{
            cursor: pointer;
            color: #D8010F;
            &:hover{
              color: #FFD200;
            }
          }
        }
        .field_phone,
        .field_email{
          display: flex;
          flex-direction: row;
          width: 100%;
          .prefix{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70px;
            font-size: 15px;
            line-height: 15px;
            font-weight: 600;
            background-color: #edeef0;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
          input{
            width: calc(100% - 70px);
            /* border-left: 0!important; */
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            :focus {
              outline: none!important;
            }
          }
        }
        .field_email{
          input{
            width: 100%;
          }
        }

        .aclaracion{
          margin-top: 1vw;
          font-size: 2.3vw;
        }
        &.field_birthdate{
          .fields_birthdate{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 2vw;
            .field_birthdate_day{
              width: 100%;//calc(50% - 4vw);
              margin-right: 4vw;
              margin-bottom: 3vw;
              select{
                width: 100%;
              }
            }
            .field_birthdate_month{
              width: 100%;//calc(50% - 4vw);
              margin-right: 4vw;
              margin-bottom: 3vw;
              select{
                width: 100%;
              }
            }
            .field_birthdate_year{
              width: 100%;
              margin-bottom: 3vw;
              select{
                width: 100%;
              }
            }
          }

          .is_invalid{
            .MuiInputBase-root{
              border: 1px solid red;
            }
          }
        }
        .is_invalid{
          border: 1px solid red;
        }
        .fix_is_invalid{
          border: 0;
        }
        label{
          display: flex;
          width: 100%;
          font-size: 3.2vw;
          line-height: 3.2vw;
          font-weight: 600;
          color: #000000;
          margin-bottom: 1.3vw;
        }
        input{
          display: flex;
          padding: 2vw;
          border: 1px solid #CCCCCC;
          border-radius: 1.2vw;
        }
        &.checkbox{
          flex-direction: row!important;
          label{
            margin-left: 10px;
          }
        }
        select{
          display: flex;
          padding: 10px;
          border: 1px solid #CCCCCC;
          height: 46px;
          border-radius: 6px;
        }
      }

      .field_double{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .field{
          /* background-color: skyblue; */
          width: 100%;//calc(50% - 2vw);
        }
        .box_field_email,
        .box_field_phone{
          display: flex;
          flex-direction: row;
          width: calc(50% - 10px);
          //width: calc(100% - 10px)!important;
          box-sizing: border-box;
          
          .bt_edit{
            display: flex;
            width: 25px;
            height: 25px;
            font-size: 25px;
            line-height: 25px;
            margin-left: 10px;
            margin-top: 32px;
            cursor: pointer;
            :hover{
              color: #FFD200;
            }
          }
          .field{
            //width: 100%;
            width: calc(100% - 35px)!important;
          }
        }
      }
      .modal_actions{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 40px;
        margin-bottom: 15px;
        .loader{
          svg{
            width: 40px;
            height: 40px;
            color: #CCCCCC!important;
          }
        }
      }


    }

  }

  @media ${breakpoints.xs} {

    justify-content: center;
    align-items: center;
    padding-top: 0;
    overflow: auto;
    z-index: 9999!important;
    width: 100%;

    .inner_modal_certificates{
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: flex-start;
      width: 700px;
      min-height: auto;
      padding: 15px 25px 15px 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;
      margin-bottom: 0;

      .form{
        width: 100%;
        //background-color: pink;
        padding-bottom: 0;
        height: auto;
        .modal_profile_edit_title{
          width: 100%;
          margin-bottom: 25px;
          margin-top: 10px;
          .modal_profile_edit_icon{
            font-size: 20px;
          }
          p{
            margin: 0;
            margin-left: 5px;
            font-size: 30px;
            line-height: 30px;
            font-weight: 400;
          }
        }
        .field{
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
          .aclaracion{
            margin-top: 5px;
            font-size: 12px;
          }
          &.field_birthdate{
            .fields_birthdate{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-top: 10px;
              .field_birthdate_day{
                width: calc(50% - 20px);
                margin-right: 20px;
                margin-bottom: 0;
                select{
                  width: 100%;
                }
              }
              .field_birthdate_month{
                width: calc(50% - 20px);
                margin-right: 20px;
                margin-bottom: 0;
                select{
                  width: 100%;
                }
              }
              .field_birthdate_year{
                width: 100%;
                margin-bottom: 0;
                select{
                  width: 100%;
                }
              }
            }
            .is_invalid{
              .MuiInputBase-root{
                border: 1px solid red;
              }
            }
          }

          .is_invalid{
            border: 1px solid red;
          }
          .fix_is_invalid{
            border: 0;
          }
          label{
            display: flex;
            width: 100%;
            font-size: 16px;
            line-height: 16px;
            font-weight: 600;
            color: #000000;
            margin-bottom: 7px;
          }
          input{
            display: flex;
            padding: 10px;
            border: 1px solid #CCCCCC;
            border-radius: 6px;
          }

          &.checkbox{
            flex-direction: row!important;
            label{
              margin-left: 10px;
            }
          }
          select{
            display: flex;
            padding: 10px;
            border: 1px solid #CCCCCC;
            height: 46px;
            border-radius: 6px;
          }
        }
        .field_double{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .field{
            width: calc(50% - 10px);
          }
        }
        .modal_actions{
          display: flex;
          justify-content: center;
          width: 100%;
          height: 40px;
          .loader{
            svg{
              width: 40px;
              height: 40px;
              color: #CCCCCC!important;
            }
          }
        }
      }
    }
  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`
const SwiperStyled = styled(Swiper)`
  width: 100%;
  padding: 0;

  .element{
    display: flex;
    flex-direction: column;
    /*cursor: pointer;*/
    .image_cetegory{
      width: 200px;
      img{
        float: left;
        width: 100%;
      }
    }
    .icon_cetegory{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      font-size: 100px;
      color: #F5F6FA;
      background-color: #FFD200;
      >.title{
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        margin-top: 5px;
      }
    }
    .trainings_details{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #F5F6FA;
      box-sizing: border-box;
      padding: 10px 10px;
      .datail{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        .label{
          font-size: 14px;
          line-height: 14px;
          font-weight: 600;
        }
        .value{
          font-size: 35px;
          line-height: 35px;
          font-weight: 600;
          margin-top: 5px;
        }
      }
    }
  }

  .content-hide{
    display: none;
  }
  .content-show{
    display: block;
  }
  .swiper-wrapper{
    .swiper-slide{
      width: auto!important;

    }
  }
  .next,
  .prev{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 26px!important;
    height: 26px!important;
    border-radius: 13px;
    background-color: #FFFFFF;
    margin-top: -13px;
    position: absolute;
    top: 69px;
    z-index: 9999;
    cursor: pointer;
  }
  .next,
  .prev{
    &.swiper-button-disabled{
      opacity: 0.5;
      cursor: default;
    }
  }
  .next{
    right: 10px;
  }
  .prev{
    left: 10px;
  }
  &.swiper_destacado{
    .prev, .next{
      top: 128px;
    }
  }
  &.swiper_contenido{
    .prev, .next{
      top: 88px;
    }
  }

  @media ${breakpoints.xs} {
    //width: 544px;
  }
  @media ${breakpoints.sm} {
    //width: 448px;
  }
  @media ${breakpoints.md} {
    //width: 672px;
  }
  @media ${breakpoints.lg} {
    //width: 840px;
  }

  /*position: absolute;*/
`

const Certificates = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  .title p {
    padding-left: 20px;
    margin: 0;
    margin-bottom: 15px;
    font-size: 25px;
    font-weight: 500;
    color: #000000;
  }
`




const PerfilContainer = () => {

  let history = useHistory();
  let location = useLocation();

  const { Auth } = useContext(AuthContext);

  const { loading,
          userData,
          setUserData,
          //studyLevelData,
          //studyStatusData,
          getByPassImageUploadRoute,
          editUserData,
          changePassword,
          edit_user_profile,
          get_training_home,
          get_home_categorie,
          trainingHome,
          setTrainingHome,
          putImageStorageUrl,
          get_url_save_photo } = useContext(ContentContext);

  const [ firstName, setFirstName ] = useState("");
  const [ lastName, setLastName ] = useState("");
  const [ dni, setDni ] = useState("");
  const [ rol, setRol ] = useState("");
  const [ puesto, setPuesto ] = useState("");
  const [ isCaptain, setIsCaptain ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ birthDate, setBirthDate ] = useState(null);
  //const [ study, setStudy ] = useState("");
  //const [ studyStatus, setStudyStatus ] = useState("");
  const [ croppedImageProfile, setCroppedImageProfile ] = useState(null);
  const [ nonChangeData, setNonChangeData ] = useState(null);
  const [ yearSelect, setYearSelect ] = useState(null);
  const [ birthDateDay, setBirthDateDay ] = useState(null);
  const [ birthDateMonth, setBirthDateMonth ] = useState(null);
  const [ birthDateYear, setBirthDateYear ] = useState(null);

  const [ passwordActual, setPasswordActual ] = useState("");
  const [ passwordNuevo, setPasswordNuevo ] = useState("");
  const [ passwordRepite, setPasswordRepite ] = useState("");
  const [ loadingFormPass, setLoadingFormPass ] = useState(false);
  const [ successFormPassword, setSuccessFormPassword ] = useState(null);
  const [ responseMessageFormPassword, setResponseMessageFormPassword ] = useState("");
  const [ certifications, setCertifications ] = useState(null);
  const [ knowledgeList, setKnowledgeList ] = useState(null);

  const [ selectedDate, handleDateChange ] = useState(new Date());
  const [ showCodePhone, setShowCodePhone ] = useState(false);
  const [ showFormEditPhone, setShowFormEditPhone ] = useState(false);
  const [ loadingNewPhone, setLoadingNewPhone ] = useState(false);
  const [ newPhoneError, setNewPhoneError ] = useState(false);
  const [ newPhone, setNewPhone ] = useState("");
  const [ codePhone, setCodePhone ] = useState("");

  const [ tryAgainEmail, setTryAgainEmail ] = useState(false);
  const [ tryAgainPhone, setTryAgainPhone ] = useState(false);
  const [ showCodeEmail, setShowCodeEmail ] = useState(false);
  const [ showFormEditEmail, setShowFormEditEmail ] = useState(false);
  const [ newEmailError, setNewEmailError ] = useState(false);
  const [ loadingNewEmail, setLoadingNewEmail ] = useState(false);
  const [ emailIsVerificationPending, setEmailIsVerificationPending ] = useState(true);
  const [ verificationEmailCodeError, setVerificationEmailCodeError ] = useState(false);
  const [ codeEmail, setCodeEmail ] = useState("");
  const [ newEmail, setNewEmail ] = useState("");
  const [ loadingValidateEmail, setLoadingValidateEmail ] = useState(false);

  const [ verificationPhoneNumberCodeError, setVerificationPhoneNumberCodeError ] = useState(false);
  const [ phoneIsVerificationPending, setPhoneIsVerificationPending ] = useState(true);
  const [ loadingValidatePhone, setLoadingValidatePhone ] = useState(false); 

  const [ codeEmailRequestSleep, setCodeEmailRequestSleep ] = useState(null);
  const [ codePhoneRequestSleep, setCodePhoneRequestSleep ] = useState(null);

  const [ newPhoneVerified, setNewPhoneVerified ] = useState(false);
  const [ newEmailVerified, setNewEmailVerified ] = useState(false);
  

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const yearLimit = moment().subtract(18, 'years').year();

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const termsURL = '/pdfs/guia_de_implementacion_shell_evolux.pdf';

const [ haveError, setHaveError ] = useState({
  first_name: { status: false, message: "" },
  last_name: { status: false, message: "" },
  email: { status: false, message: "" },
  phone: { status: false, message: "" },
  birth_date: { status: false, message: "" },
  //study: { status: false, message: "" },
  //study_state: { status: false, message: "" },
});


/*
useEffect(() => {

  get_training_home();

  return () => {}
}, [get_training_home])

useEffect(() => {

  if(trainingHome){
    console.log("trainingHome", trainingHome)
    trainingHome.categories.map((category, index) => {
      console.log("category", category._id);
      const response = get_home_categorie(category._id);
      response.then((res) => {
        console.log("res", res)
      })
    })
  }

  return () => {}
}, [trainingHome])
*/


useEffect(() => {

  if(userData){

    handleSetDataInputForm();

    setBirthDateDay(userData.birthday ? userData.birthday.split('-')[2].substring(0,2) : '');
    setBirthDateMonth(userData.birthday ? userData.birthday.split('-')[1] : '');
    setBirthDateYear(userData.birthday ? userData.birthday.split('-')[0] : '');

    let avatar_image = userData.avatar;
    // let avatar_image = null;
    // if(userData.avatar_image.length){
    //   avatar_image = userData.avatar_image[0].media;
    // }

    setNonChangeData({
      first_name: userData.name,
      last_name: userData.last_name,
      dni: userData.dni,
      email: userData.email,
      phone: userData.phone,
      is_captain: true,//userData.is_captain,
      birth_date: userData.birthday ? userData.birthday.split('-')[2].substring(0,2) + "/" + userData.birthday.split('-')[1] + "/" + userData.birthday.split('-')[0]/*moment(userData.birthday).utcOffset(0, true).format('L')*//*moment(userData.birthday).format("DD/MM/yyyy")*/ : '',
      //level_of_study: userData.level_of_study,
      //level_of_study_status: userData.level_of_study_status,
      image_profile: userData.media ? userData.media : null,//avatar_image,//
      rol: userData.rol,//fixRolName(userData.rol),
      position: userData.position,
      cadena: userData.cadena,
    })

    if(userData.birthday){

    }else{

    }

    

    fillSelect();

  }

  return () => {}

}, [userData])

const handleSetDataInputForm = () => {

  resetFormErrorObject();

  setFirstName(userData.name ? userData.name : "");
  setLastName(userData.last_name ? userData.last_name : "");
  setDni(userData.dni ? userData.dni : "");
  setRol("")
  setPuesto("")
  setIsCaptain("NO")
  setEmail(userData.email ? userData.email : "");
  setPhone(userData.phone ? userData.phone : "");
  setBirthDate(userData.birthday ? userData.birthday : "");
  //setStudy(userData.level_of_study ? userData.level_of_study : "");
  //setStudyStatus(userData.level_of_study_status ? userData.level_of_study_status : "");

  if(userData.birthday){
    const birthdayFormat = moment(userData.birthday).format("yyyy-MM-DD");

    setBirthDateDay(userData.birthday.split('-')[2].substring(0,2));
    setBirthDateMonth(userData.birthday.split('-')[1]);
    setBirthDateYear(userData.birthday.split('-')[0]);
  }

}

  useEffect(() => {

  return () => {};
  }, [birthDateDay, birthDateMonth, birthDateYear]);


const resetFormErrorObject = () => {

  if (Object.keys(haveError).length) {
    Object.keys(haveError).map(function (key, index) {
      haveError[key] = { status: false };
    });
  }

}

const handleSaveProfileData = () => {

  let error = false;
  const birthDateDayValid = moment(birthDateYear + '-' + birthDateMonth + '-' + birthDateDay).isValid();
  resetFormErrorObject();

  let haveErrorProcess = {...haveError};
  if(firstName.length <= 0){
    error = true;
    haveErrorProcess.first_name.status = true;
  }
  if(lastName.length <= 0){
    error = true;
    haveErrorProcess.last_name.status = true;
  }
  if(email.length <= 0){
    error = true;
    haveErrorProcess.email.status = true;
  }else{
    if(!validateEmail(email)){
      error = true;
      haveErrorProcess.email.status = true;
    }
  }
  if(phone.length != 0){
    let pluscincuenta = phone.substr(0, 3);
        if(phone.length > 3){

          if(pluscincuenta != "+54"){
            error = true;
            haveErrorProcess.phone.status = true;
          }

    }else{

      error = true;
      haveErrorProcess.phone.status = true;

    }
  }

  if(!birthDateDayValid){
    error = true;
    haveErrorProcess.birth_date.status = true;
  }

  setHaveError(haveErrorProcess);

  if(error){
    return null;
  }

  const data_edited = {name: firstName,
                       last_name: lastName,
                       //email: email,
                       //phone: phone,
                       birthday: birthDateYear + '-' + birthDateMonth + '-' + birthDateDay};

  const payload = {user_data: data_edited/*JSON.stringify(data_edited)*/}

  edit_user_profile(payload);
  //editUserData(payload);

  setNonChangeData(prevState => ({
    ...prevState,
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone: phone.length,
    birth_date: birthDateDay + '/' + birthDateMonth + '/' + birthDateYear,
  }));

  setUserData(prevState => ({...prevState, ...data_edited}));

  handleClose();

}











  const [ openModalImageProfile, setOpenModalImageProfile ] = useState(false);
  const [ upImg, setUpImg ] = useState();
  const imgRef = useRef(null);
  const inputFileRef = useRef( null );
  const imgCroppedRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [ crop, setCrop ] = useState({
    unit: 'px',
    width: 250,
    minWidth: 250,
    minHeight: 250,
    maxWidth: 500,
    maxHeight: 500,
    aspect: 1 / 1
  });
  const [ completedCrop, setCompletedCrop ] = useState(null);

  const [ src, selectFile ] = useState(null);

  const getCroppedImg = () => {
    const canvas = document.createElement("canvas");
    const scaleX = upImg.naturalWidth / upImg.width;
    const scaleY = upImg.naturalHeight / upImg.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
    upImg,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
    );

    // As Base64 string
    const base64Image = canvas.toDataURL("image/jpeg");
    return base64Image;

    // As a blob


    /*
    return new Promise((resolve, reject) => {
    canvas.toBlob(
    (blob) => {
    blob.name = fileName;
    resolve(blob);
    },
    "image/jpeg",
    1
    );
    });
    */
  }

  const onSelectFile = (e) => {
    selectFile(URL.createObjectURL(e.target.files[0]));
    setUpImg(e.target.files[0]);
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

  }, [completedCrop]);


  const handleProfileImage = () => {

    setOpenModalImageProfile(true);

  }

  const handleGenerateThumb = () => {
    
    const croppedImg = getCroppedImg();
    const fileName = get_url_save_photo(userData.url_uploads+"/user");
    fileName.then(res => {
      console.log("userData", res);
      const payload = { filename: res, blob: croppedImg };
      const imageUploaded = putImageStorageUrl(payload);
      imageUploaded.then(image => {
        console.log("imageUploaded", image);
        setCroppedImageProfile(croppedImg);
        setOpenModalImageProfile(false);
        selectFile(null);
      })

    })

    
    
    return null;

    
    

    /*
    const completeUpload = getByPassImageUploadRoute(payload);

    completeUpload.then(() => {

      setCroppedImageProfile(croppedImg);
      setOpenModalImageProfile(false);
      selectFile(null);

    })
    */

  }


  const handleBrowseProfileImage = () => {
    inputFileRef.current.click();
  }

  const handleDownloadTerms = () => {
    downloadFileFromUrl(termsURL, 'terminos_y_condiciones_somosprotagonistas.pdf');
  }

  const handleEditImage = () => {
    
    selectFile(null);
    setCroppedImageProfile(null);
    setOpenModalImageProfile(true);
    //const payload = { image_name: "text_image_name.jpg" };
    //getByPassImageUploadRoute(payload);
  }

  const handleCancelImageProfile = () => {
    setOpenModalImageProfile(false);
  }

  const fillSelect = () => {
    let items = [];
    for (let year = yearLimit; year > 1900; year--) {
      items.push(year);
    }
    setYearSelect(items);
  }




  const handleChangePassword = () => {
  ///<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=;

    let hayError = false;
    let spChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;


    if(passwordActual.length <= 0 || passwordNuevo.length <= 0 || passwordRepite.length <= 0){
      handleFormPasswordResponseStatus(0, 'Todos los campos son obligatorios');
      return null;
    }
    if(passwordActual.length <= 0){
      handleFormPasswordResponseStatus(0, 'La contraseña ingresada es incorrecta');
      return null;
    }
    if(passwordNuevo.length < 8){
      handleFormPasswordResponseStatus(0, 'Debe tener 8 o más caracteres');
      return null;
    }
    if(passwordNuevo !== passwordRepite){
      handleFormPasswordResponseStatus(0, 'Las contraseñas no coinciden');
      return null;
    }

    if(!spChars.test(passwordNuevo)){
      handleFormPasswordResponseStatus(0, 'Revisa que tu contraseña contenga caracteres especiales (ej: @#.)');
      return null;
    }



    if(hayError){
      return null;
    }

    const payload = {password: passwordActual,
            new_password: passwordNuevo,
            re_password: passwordRepite,}

    setLoadingFormPass(true);
    const response = changePassword(payload);
    response.then(res => {

      handleFormPasswordResponseStatus(res.data.status, res.data.mensaje);

    })


  }

  const handleFormPasswordResponseStatus = (status, message) => {
    if(status === 1){
      setSuccessFormPassword(true);
    }else{
      setSuccessFormPassword(false);
    }
    setResponseMessageFormPassword(message);
    setTimeout(closeFormPasswordErrorMessage, 3500);
    setLoadingFormPass(false);
  }

  function closeFormPasswordErrorMessage() {
    setResponseMessageFormPassword("");
    setSuccessFormPassword(null);
  }

  useEffect(() => {
    //console.log("nonChangeData", nonChangeData)

    return () => {}
  }, [nonChangeData])

  const handleGoToCourse = (_id) => {
    history.push(`/campus/category/${_id}`);
  }

  const handleChangePhone = (show) => {

    setShowFormEditPhone(!showFormEditPhone);
    if(!codePhoneRequestSleep){
      setShowCodePhone(show);
      setNewPhone("");
    }
    
  }

  const handleChangeEmail = (show) => {

    setShowFormEditEmail(!showFormEditEmail);
    if(!codeEmailRequestSleep){
      setShowCodeEmail(show);
      setNewEmail("");
    }
    
  }

  const handleCodeEmailRequestSleepComplete = () => {

    setShowCodeEmail(false);
    setTryAgainEmail(true);
    setNewEmail("");

  }

  const handleSaveNewPhone = async () => {
    
    const user = await Auth.currentAuthenticatedUser();

    try {
      setLoadingNewPhone(true);
      setNewPhoneError(false);
      const updatedAttributes = {}
            updatedAttributes.phone_number = '+549' + newPhone;//'+549' + 
            console.log("Auth", Auth);
            let update = await Auth.updateUserAttributes(user, updatedAttributes);
            console.log("update", update);
      
      setLoadingNewPhone(false);
      setShowCodePhone(true);
      setCodePhoneRequestSleep(Date.now() + 60000);
      setTryAgainPhone(false);
    } catch (error) {

      setLoadingNewPhone(false);
      setNewPhoneError(true);
      //setLoadingValidatePhone(false);
      console.error("Error verificando el teléfono:", error.message);

    }

  }

  const handleNewPhoneCode = async () => {
    
    setVerificationPhoneNumberCodeError(false);
    if(codePhone.length){
      console.log("codePhone", codePhone)
      try {
          setLoadingValidatePhone(true);
          await Auth.verifyCurrentUserAttributeSubmit('phone_number', codePhone);

          const userData_update = {...userData};
          userData_update.phone_verified = true;
          setUserData(userData_update);

          setPhoneIsVerificationPending(false);
          setLoadingValidatePhone(false);
          handleSaveProfilePhoneData();

          setNewPhoneVerified(true);
          
      } catch (verificationError) {
          setLoadingValidatePhone(false);
          setVerificationPhoneNumberCodeError(true);
          console.error("Error verificando el teléfono:", verificationError.message);
      }
    }else{
      //alert("asd")
      setVerificationPhoneNumberCodeError(true);
    }

  }

  const handleCodePhoneRequestSleepComplete = () => {

    setShowCodePhone(false);
    setTryAgainPhone(true);
    setNewPhone("");

  }

  const handleNewEmailCode = async () => {
    setVerificationEmailCodeError(false);
    if(codeEmail.length){
      try {
          setLoadingValidateEmail(true);
          await Auth.verifyCurrentUserAttributeSubmit('email', codeEmail);

          const userData_update = {...userData};
          userData_update.email_verified = true;
          setUserData(userData_update);

          setEmailIsVerificationPending(false);
          setLoadingValidateEmail(false);
          handleSaveProfileEmailData();

          setNewEmailVerified(true);
          //setShowCodePhone(false);
          //setCodePhoneRequestSleep(null);

      } catch (verificationError) {
          setLoadingValidateEmail(false);
          setVerificationEmailCodeError(true);
          console.error("Error verificando el teléfono:", verificationError.message);
      }
    }else{
      setVerificationEmailCodeError(true);
    }
  }

  const validatePhoneFormat = (e) => {

    const numericRegex = /^[0-9]*$/;
    if(numericRegex.test(e.target.value) && e.target.value.length <= 10){
      setNewPhone(e.target.value);
      return null;
    }

  }

  const validateCode = (e, type) => {

    const numericRegex = /^[0-9]*$/;
    if(numericRegex.test(e.target.value) && e.target.value.length <= 6){
      if(type === "phone"){
        setCodePhone(e.target.value);
      }
      if(type === "email"){
        setCodeEmail(e.target.value);
      }
      return null;
    }

  }

  const validateEmailFormat = (e) => {

    setNewEmail(e.target.value);
    /*
    const numericRegex = /^[0-9]*$/;
    if(numericRegex.test(e.target.value) && e.target.value.length <= 10){
      setNewEmail(e.target.value);
      return null;
    }
    */
  }



  const handleSaveProfilePhoneData = () => {
  
    const data_edited = { phone: '+549' + newPhone };
    const payload = { user_data: data_edited }
  
    edit_user_profile(payload);
    console.log('+549' + newPhone);
    setUserData(prevState => ({...prevState, ...{ phone: data_edited.phone }}));
  
  }

  const handleSaveProfileEmailData = () => {
  
    const data_edited = { email: newEmail };
    const payload = { user_data: data_edited }
  
    edit_user_profile(payload);
    setUserData(prevState => ({...prevState, ...{ email: newEmail }}));
  
  }
  

  const handleSaveNewEmail = async () => {
    const user = await Auth.currentAuthenticatedUser();
    if(newEmail.length && validateEmail(newEmail)){
      setLoadingNewEmail(true);
      setNewEmailError(false);
      const updatedAttributes = {}
            updatedAttributes.email = newEmail;
            let update = await Auth.updateUserAttributes(user, updatedAttributes);
      setLoadingNewEmail(false);
      setShowCodeEmail(true);
      setCodeEmailRequestSleep(Date.now() + 60000);
      setTryAgainEmail(false);
    }else{
      setLoadingNewEmail(false);
      setNewEmailError(true);
    }
  }

  useEffect(() => {
    //setCodeEmailRequestSleep(Date.now() + 60000);
    console.log("codeEmailRequestSleep", codeEmailRequestSleep)
    return () => {}
  }, [codeEmailRequestSleep])
  useEffect(() => {
    console.log("ShowCodeEmail", showCodeEmail)
  
    return () => {}
  }, [showCodeEmail])
  
  

  

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const handleCloseModalCog = () => {
    
    setLoadingValidatePhone(false);
    setPhoneIsVerificationPending(true);
    setShowFormEditPhone(false);
    //setShowCodePhone(false);
    setCodePhone("");
    //setNewPhone("");
    setNewPhoneError(false);
    setVerificationPhoneNumberCodeError(false);

    setCodeEmail("");
    //setNewEmail("");
    //setShowCodeEmail(false);
    setShowFormEditEmail(false);
    setVerificationEmailCodeError(false);
    setEmailIsVerificationPending(true);
    setLoadingValidateEmail(false);

    if(newPhoneVerified){
      setShowCodePhone(false);
      setCodePhoneRequestSleep(null);
      setNewPhoneVerified(false);
    }
    
    if(newEmailVerified){
      setShowCodeEmail(false);
      setCodeEmailRequestSleep(null);
      setNewEmailVerified(false);
    }
    
  }

  useEffect(() => {

    handleCloseModalCog();

    return () => {
      
    }
  }, [open])

  

  return (
    <Wrapper>







      <ModalStyledForm
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
      }}
      >

        <Fade in={open}>
          <div className="inner_modal_certificates">

          

          {showFormEditPhone ? 
            <div className='box_edit_phone'>

              <ButtonClose className='bt_close_modal_cog' onClick={() => handleCloseModalCog()}>
                <div className="icon">
                  <RiCloseCircleLine />
                </div>
              </ButtonClose>

              <div className='inner'>
                
                <div className='form form_edit_phone'>
                  <div className="field">
                    <label htmlFor="first_name">Nuevo Celular</label>
                    <div className='field_phone'>
                      <div className='prefix'>+549</div>
                      <input
                        type="text"
                        id="new_phone"
                        name="new_phone" 
                        onChange={(e) => validatePhoneFormat(e)}
                        value={newPhone}
                        disabled={showCodePhone}
                        className={newPhoneError ? "is_invalid" : ""}
                      />
                    </div>
                    <span className="aclaracion">Ingreá solo el código de area y tu teléfono, sin el 15 (ej: 11XXXXXXXX para AMBA){/*El número debe contener +54 (Código de área) sin el 15 (ej: +5411987654321 para AMBA)*/}</span>
                    {newPhoneError ? <div className='error_message'>Hubo un problema con el número de celular ingresado. Por favor, corregilo y volvé a intentarlo.</div> : ""}
                    {!showCodePhone ? 
                    <div className='new_phone_actions'>
                      {loadingNewPhone ?
                        <div className="loader">
                          <CircularProgress size={30}/>
                        </div>
                        :
                        <ButtonYellow
                          className="bt_save_form"
                          onClick={() => handleSaveNewPhone()}
                          disabled={false}
                          css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '100%'}}
                        >
                          <p>GUARDAR</p>
                        </ButtonYellow>
                      }
                    </div> : "" }
                  </div>
                </div>
                
                {showCodePhone ? 
                <div className='form form_code_phone'>
                  <div className="field">
                    <label htmlFor="first_name">Código de verificación</label>
                    <input
                      type="text"
                      id="code_phone"
                      name="code_phone"
                      onChange={(e) => validateCode(e, "phone")}
                      value={codePhone}
                      disabled={false}
                      className={verificationPhoneNumberCodeError ? "is_invalid" : ""}
                    />
                    <span className="aclaracion">Ingresá el código de verificación que te enviamos al número: {newPhone}</span>
                    {verificationPhoneNumberCodeError ? <div className='error_message'>No se pudo actualizar tu número de celular porque hay un error en el código de verificación ingresado.</div> : "" }
                    <div className='code_phone_actions'>

                      {loadingValidatePhone ? 
                        <div className="loader">
                          <CircularProgress size={30} />
                        </div>
                      :
                        phoneIsVerificationPending ?
                        <ButtonYellow 
                          className="bt_save_form" 
                          onClick={() => handleNewPhoneCode()}
                          disabled={false}
                          css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '100%'}}
                        >
                          <p>VERIFICAR</p>
                        </ButtonYellow>
                        : <div className='virified'>Verificado</div>
                      }
                    </div>
                  </div>
                </div> : "" }



              </div>
            </div> 
          : "" }







          {showFormEditEmail ? 
            <div className='box_edit_email'>

              <ButtonClose className='bt_close_modal_cog' onClick={() => handleCloseModalCog()}>
                <div className="icon">
                  <RiCloseCircleLine />
                </div>
              </ButtonClose>

              <div className='inner'>
                
                <div className='form form_edit_email'>
                  <div className="field">
                    <label htmlFor="first_name">Nuevo E-Mail</label>
                    <div className='field_email'>
                      <input
                        type="text"
                        id="new_email"
                        name="new_email" 
                        onChange={(e) => validateEmailFormat(e)}
                        value={newEmail}
                        disabled={showCodeEmail}
                        className={newEmailError ? "is_invalid" : ""}
                      />
                    </div>
                    <span className="aclaracion">Recordá que el E-Mail tiene que contener @ y .</span>
                    {newEmailError ? <div className='error_message'>Hubo un problema con el e-mail ingresado. Por favor, corregilo y volvé a intentarlo.</div> : ""}
                    {!showCodeEmail ? 
                    <div className='new_email_actions'>
                      {loadingNewEmail ?
                        <div className="loader">
                          <CircularProgress size={30}/>
                        </div>
                        :
                        <ButtonYellow
                          className="bt_save_form"
                          onClick={() => handleSaveNewEmail()}
                          disabled={false}
                          css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '100%'}}
                        >
                          <p>GUARDAR</p>
                        </ButtonYellow>
                      }
                    </div> : "" }
                  </div>
                </div>
                
                {showCodeEmail ? 
                <div className='form form_code_email'>
                  <div className="field">
                    <label htmlFor="first_name">Código de verificación</label>
                    <input
                      type="text"
                      id="code_email"
                      name="code_email"
                      onChange={(e) => validateCode(e, "email")}
                      value={codeEmail}
                      disabled={false}
                      className={verificationEmailCodeError ? "is_invalid" : ""}
                    />
                    <span className="aclaracion">Ingresá el código de verificación que te enviamos al número: {newEmail}</span>
                    {verificationEmailCodeError ? <div className='error_message'>No se pudo actualizar tu número de celular porque hay un error en el código de verificación ingresado.</div> : "" }
                    <div className='code_email_actions'>

                      {loadingValidateEmail ? 
                        <div className="loader">
                          <CircularProgress size={30} />
                        </div>
                      :
                        emailIsVerificationPending ?
                        <ButtonYellow 
                          className="bt_save_form" 
                          onClick={() => handleNewEmailCode()}
                          disabled={false}
                          css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '100%'}}
                        >
                          <p>VERIFICAR</p>
                        </ButtonYellow>
                        : <div className='virified'>Verificado</div>
                      }
                    </div>
                  </div>
                </div> : "" }



              </div>
            </div> 
          : "" }



                        



          <ButtonClose onClick={() => handleClose()}>
            <div className="icon">
              <RiCloseCircleLine />
            </div>
          </ButtonClose>




          <div className="form">

            <div className="modal_profile_edit_title">
              <div className="modal_profile_edit_icon">
                <RiPencilFill />
              </div>
              <p>Edita tu Perfil: </p>
            </div>

        <div className="field_double">
          <div className="field">
            <label htmlFor="first_name">Nombre</label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              disabled={false}
              className={haveError.first_name.status ? "is_invalid" : ""}
            />
          </div>
          <div className="field">
            <label htmlFor="first_name">Apellido</label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              disabled={false}
              className={haveError.last_name.status ? "is_invalid" : ""}
            />
          </div>
        </div>
        <div className="field_double">

          <div className='box_field_phone'>
            <div className="field">
              <label htmlFor="first_name">Celular</label>
              <input
                type="text"
                id="phone"
                name="phone"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                disabled={true}
                className={haveError.phone.status ? "is_invalid" : ""}
              />

              {codePhoneRequestSleep !== null ?
                <div className='countdown'>
                  {!tryAgainPhone ? 
                    <>Reintentar en: <Countdown key={"countdown_email"} date={codePhoneRequestSleep} onComplete={handleCodePhoneRequestSleepComplete} renderer={props => <div className='count_value'>{zeroPad(props.seconds)}</div>} /></>
                  : <div className='bt_reintentar' onClick={() => handleChangePhone(codePhoneRequestSleep === null ? false : true)}>Reintentar</div> }
                </div>
              : "" }

              {codePhoneRequestSleep === null & userData?.phone_verified === true ? 
              <div className='verificado'>
                <div className='icon'>
                  <FaCheck />
                </div>
                <p>Verificado</p>
              </div>
              : "" }

              {codePhoneRequestSleep === null & userData?.phone_verified === false ? 
              <div className='sin_verificado'>
                <div className='icon'>
                  <FaTimes />
                </div>
                <p>Sin Verificar</p>
              </div>
              : "" }
              
              {/* <span className="aclaracion">El número debe contener +54 (Código de área) sin el 15 (ej: +5411987654321 para AMBA)</span> */}
            </div>
            <div className='bt_edit' onClick={() => handleChangePhone(codePhoneRequestSleep === null ? false : true)}>
              <FaEdit />
            </div>
          </div>

          <div className='box_field_email'>
            <div className="field">
              <label htmlFor="first_name">E-Mail</label>
              <input
                type="text"
                id="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                disabled={true}
                className={haveError.email.status ? "is_invalid" : ""}
              />

              {codeEmailRequestSleep !== null ?
                <div className='countdown'>
                  {!tryAgainEmail ? 
                    <>Reintentar en: <Countdown key={"countdown_email"} date={codeEmailRequestSleep} onComplete={handleCodeEmailRequestSleepComplete} renderer={props => <div className='count_value'>{zeroPad(props.seconds)}</div>} /></>
                  : <div className='bt_reintentar' onClick={() => handleChangeEmail(codeEmailRequestSleep === null ? false : true)}>Reintentar</div> }
                </div>
              : "" }

              {codeEmailRequestSleep === null & userData?.email_verified === true ? 
              <div className='verificado'>
                <div className='icon'>
                  <FaCheck />
                </div>
                <p>Verificado</p>
              </div>
              : "" }

              {codeEmailRequestSleep === null & userData?.email_verified === false ? 
              <div className='sin_verificado'>
                <div className='icon'>
                  <FaTimes />
                </div>
                <p>Sin Verificar</p>
              </div>
              : "" }

            </div>
            <div className='bt_edit' onClick={() => handleChangeEmail(codeEmailRequestSleep === null ? false : true)}>
              <FaEdit />
            </div>
          </div>

        </div>

        <div className="field field_birthdate">
          <label>Fecha de Nacimiento</label>
          <div className="fields_birthdate">
            <div className="field_birthdate_day">
              <label>Dia</label>
              <select
                value={birthDateDay == null ? "" : birthDateDay}
                onChange={(e) => { setBirthDateDay(e.target.value); } }
                className={haveError.birth_date.status ? "is_invalid" : ""}
              >
                <option vlaue="">Selecciona una opción</option>
                {
                  days.map((day, index) => {
                    return(
                      <option vlaue={day}>{day}</option>
                    )
                  })
                }
              </select>
            </div>

            <div className="field_birthdate_month">
              <label>Mes</label>
              <select
                value={birthDateMonth == null ? "" : birthDateMonth}
                onChange={(e) => { setBirthDateMonth(e.target.value); }}
                className={haveError.birth_date.status ? "is_invalid" : ""}
              >
                <option vlaue="">Selecciona una opción</option>
                {
                  months.map((month, index) => {
                    return(
                      <option vlaue={month}>{month}</option>
                    )
                  })
                }
              </select>
            </div>

            <div className="field_birthdate_year">
              <label>Año</label>
              <select
                value={birthDateYear == null ? "" : birthDateYear}
                onChange={(e) => { setBirthDateYear(e.target.value); }}
                className={haveError.birth_date.status ? "is_invalid" : ""}
              >
                <option vlaue="">Selecciona una opción</option>
                {yearSelect && yearSelect.map((year, index) => {
                  return <option key={index} value={year}>{year}</option>
                })}
              </select>
            </div>
          </div>


        </div>

        <div className="modal_actions">

          {loading ?
            <div className="loader">
              <CircularProgress />
            </div>
            :
            <ButtonYellow
              className="bt_save_form"
              onClick={() => handleSaveProfileData()}
              disabled={false}
              css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
            >
              <p>GUARDAR</p>
            </ButtonYellow>
          }
        </div>









          </div>







          </div>
        </Fade>

      </ModalStyledForm>









      <ModalImageProfileStyled
        disableAutoFocus={true}
        open={openModalImageProfile}
        onClose={() => setOpenModalImageProfile(!openModalImageProfile)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
      <Fade in={openModalImageProfile}>

      <ContainerModalProfileImage>
        {loading &&
        <div className="modal_block">
          <CircularProgress />
        </div>
        }
          {/*
          <button
              onClick={() => setOpenModalImageProfile(!openModalImageProfile)}
          >
              <div className="icon">
                  x
              </div>
          </button>
          */}
          <div className="modal_imageprofile">

          {src ?
              <div className="modal_imageprofile_container">
                  <div className="modal_imageprofile_image">
                    <ReactCrop
                        src={src}
                        onImageLoaded={setUpImg}
                        //onImageLoaded={onLoad}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                    />
                  </div>

                  <div className="actions">

                      <ButtonYellow
                        className="bt_save"
                        onClick={() => handleGenerateThumb()}
                        disabled={false}
                        css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                      >
                        <p>Guardar Imagen</p>
                      </ButtonYellow>

                      <ButtonYellow
                        className="bt_cancel"
                        onClick={() => handleCancelImageProfile()}
                        disabled={false}
                        css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                      >
                        <p>Cancelar</p>
                      </ButtonYellow>

                  </div>
              </div>

          :

              <div className="content_action_browse">
                  <input ref={inputFileRef} type="file" accept="image/*" onChange={(e) => onSelectFile(e)} />
                  <ButtonBrowseProfileImage onClick={() => handleBrowseProfileImage()}>
                      <div className="icon">
                          <IoCloudUploadOutline />
                      </div>
                  </ButtonBrowseProfileImage>

                  <ButtonYellow
                    className="bt_cancel"
                    onClick={() => handleCancelImageProfile()}
                    disabled={false}
                    css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                  >
                    <p>Cerrar</p>
                  </ButtonYellow>

              </div>

          }


          </div>
      </ContainerModalProfileImage>

      </Fade>

      </ModalImageProfileStyled>

      <ProfileDetail>

      <div className="profile_data">
      <div className="profile_data_header">

      <div className="profile_image">
          <div className="button_edit_image" onClick={() => handleEditImage()}>
            <RiPencilFill />
          </div>
        <div className="image">
          <img src={croppedImageProfile ? croppedImageProfile : nonChangeData?.image_profile ? nonChangeData.image_profile : "images/noavatar.jpg"} />
          {/*<img src="images/noavatar.jpg" />*/}
        </div>
      </div>

      <div className="profile_name">
        <div className="field_data name">
          <p className="value">{nonChangeData ? nonChangeData.first_name : ''} {nonChangeData ? nonChangeData.last_name : ''}</p>
          <div className="button_edit" onClick={() => handleOpen()}>
            <RiPencilFill />
          </div>
        </div>
        <div className="field_data rol_puesto">
          <p className="value">{`${nonChangeData?.rol ? nonChangeData.rol : ''} ${nonChangeData?.position ? ' - ' + nonChangeData.position : ''} ${nonChangeData?.captain ? ' - Capitán' : ''}`}</p>
        </div>
        {/* <div className="field_data estation">
          <p className="value">{`Estación: ${nonChangeData?.cadena ? nonChangeData.cadena : ''}`}</p>
        </div> */}
      </div>

      </div>


      <div className="field_data_container">
        <div className="field_data">
          <p className="label">D.N.I.: </p>
          <p className="value">{nonChangeData ? nonChangeData.dni : ''}</p>
        </div>
        <div className="field_data study">
          <p className="label">Celular: </p>
          <p className="value">{nonChangeData && nonChangeData.phone ? nonChangeData.phone : '-'}</p>
          {userData?.phone_verified ? 
          <div className='verificado'>
            <div className='icon'>
              <FaCheck />
            </div>
            <p>Verificado</p>
          </div>
          :
          <div className='sin_verificado'>
            <div className='icon'>
              <FaTimes />
            </div>
            <p>Sin Verificar</p>
          </div>
          }
        </div>
        <div className="field_data">
          <p className="label">E-Mail: </p>
          <p className="value">{nonChangeData ? nonChangeData.email : ''}</p>

          {userData?.email_verified ? 
          <div className='verificado'>
            <div className='icon'>
              <FaCheck />
            </div>
            <p>Verificado</p>
          </div>
          :
          <div className='sin_verificado'>
            <div className='icon'>
              <FaTimes />
            </div>
            <p>Sin Verificar</p>
          </div>
          }
        </div>
        <div className="field_data">
          <p className="label">Fecha de Nacimiento: </p>
          <p className="value">{nonChangeData && nonChangeData.birth_date ? nonChangeData.birth_date/*moment(nonChangeData.birth_date).format('DD/MM/YYYY')*/ : '-'}</p>
        </div>
        {/*
        <div className="field_data study">
          <p className="label">Estudios: </p>
          <p className="value">{`${nonChangeData && nonChangeData.level_of_study ? nonChangeData.level_of_study : '-'} ${nonChangeData && nonChangeData.level_of_study_status ? nonChangeData.level_of_study_status : '-'}`}</p>
        </div>
        */}
      </div>

      </div>
      </ProfileDetail>

      {/* <ContainerPassword>

        <div className="title">
          <p>
            Modificá tu contraseña
          </p>
        </div>

        <div className="innerContainerPassword">
          <ChangePassword requestTo={edit_user_profile} scope={"profile"} isModal={false} />
        </div>

      </ContainerPassword> */}

      {knowledgeList && Object.keys(knowledgeList).length ?
      <Certificates>
        <div className="title">
          <p>
            Certificados
          </p>
        </div>
        <SwiperStyled
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}

          modules={[Navigation]}
          spaceBetween={23}
          slidesPerView={"auto"}
          className="swiper_destacado"
        >


          {Object.keys(knowledgeList).map(function(key) {
            return(
              <SwiperSlide>
                <div className='element' /*onClick={() => handleGoToCourse(knowledgeList[key]._id)}*/>
                  {knowledgeList[key].icon ?
                  <div className='image_cetegory'>
                    <img src={`${knowledgeList[key].icon}`}/*'https://resources-one.s3.us-east-2.amazonaws.com/sp/contenidos/icons/lubricantes.jpg'*/ />
                  </div>
                  :
                  <div className='icon_cetegory'>
                    <TbFileCertificate />
                    <div className='title'>{knowledgeList[key].name}</div>
                  </div>
                  }
                  <div className='trainings_details'>
                    <div className='datail'>
                      <div className='label'>Completos</div>
                      <div className="value">{`${(knowledgeList[key].courses.filter(c => c.status === 3)).length}`}</div>
                    </div>
                    <div className='datail'>
                      <div className='label'>Pendientes</div>
                      <div className="value">{`${(knowledgeList[key].courses.filter(c => c.status !== 3)).length}`}</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
          <div ref={prevRef} className="prev">
            <FaChevronLeft />
          </div>
          <div ref={nextRef} className="next">
            <FaChevronRight />
          </div>

        </SwiperStyled>
      </Certificates> : ""}

      <Terms>
        <div className="title">
          <p>
            Reglamento
          </p>
        </div>
        <div className="text_terms">
          <div className="inner_terms">
            <TermsText />
          </div>
        </div>
      </Terms>

    </Wrapper>
  )
}

export default PerfilContainer;
