import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';
import { Logo,
         Spinner } from '../../_helpers/constants';
import { AuthContext } from '../../_context/AuthContext';
import storage from 'local-storage-fallback';
import { breakpoints } from '../../_helpers/breakpoints';
import Login from '~/_components/Login/Login';
import Retrieve from '~/_components/Login/Retrieve';
import CompleteUser from '~/_components/Login/CompleteUser';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F5F6FA;
  .hide{
    color: #F5F6FA;
  }
  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`
const BoxLogin = styled.div`
  display: flex;
  width: calc(100% - 30px);
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;

  box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);
  -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);

  @media ${breakpoints.xs} {
    width: 530px;
  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }

`
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  .bt_login{
    margin-top: 15px;
  }
  .message_pass_change{
    margin-top: 15px;
    margin-bottom: 5px;
    font-size: 13.5px;
    line-height: 16.5px;
    text-align: center;
    color: #333;
  }
`
const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &.field_password{
    margin-top: 15px;
  }
`
const Label = styled.label`
  display: flex;
  width: 100%;
  margin-bottom: 3px;
  color: #242424;
`
const Input = styled.input`
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  border: 1px solid #edeef0;
  ::placeholder {
    color: #a8a8a8;
    font-size: 16px;
  }
  :focus{
    outline: none;
  }
  *:focus {
    outline: none;
  }
  &.error{
    border: 1px solid red!important;
  }
`
const ButtonForgot = styled.div`

  display: flex;
  justify-content: flex-end;
  width: 100%;

  font-size: 14px;
  margin-top: 7px;

  a{
    cursor: pointer;
    float: left;
    p{
      color: #242424;
      margin: 0;
    }
  }

  a:hover{
    text-decoration: none;
    color: #FFD200;
    p{
      color: #FFD200;
    }
  }

`
const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 30px;
  svg{
    width: 55vw;
  }

  @media ${breakpoints.xs} {
    width: 100%;
    svg{
      width: 300px;
    }
  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .loader{
    margin-top: 15px!important;
    height: 40px;
  }
`
const ErrorMessage = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3px;
  p{
    color: #E02020;
    width: 100%;
    font-size: 14px;
    text-align: right;
  }
`

const LoginContainer = () => {

  const { login,
          isAuthLoading,
          loginError,
          setLoginError,
          newPasswordRequired, 
          setNewPasswordRequired } = useContext(AuthContext);


  const [ user, setUser ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ loading, setLoading ] = useState(false);

  const [showLogin, setShowLogin] = useState(true);//false
  const [showRetrieve, setShowRetrieve] = useState(false);

  const [ errorFields, setErrorFields ] = useState({user: false, password: false});

  const handleFormSubmit = async () => {

    if(!user){
      /*
      setErrorFields(prev => ({
        ...prev,
        user: true,
      }));
      */
    }
    if(!password){
      /*
      setErrorFields(prev => ({
        ...prev,
        password: true,
      }));
      */
    }

    setErrorFields({user: !user ? true : false, password: !password ? true : false})

    if(!user || !password){

      setLoading(false);
      return null;

    }

    setLoading(true);

    try {

      const result = await login(user, password);
      result.then(() => {
        setLoading(false);
      })


    } catch (e) {

    }

  };

  const handleShowRetrieve = () => {

    setShowLogin(false);
    setShowRetrieve(true);

  }

  const handleShowBackLogin = () => {
    
    setLoginError(false);
    setShowLogin(true);
    setShowRetrieve(false);

  }

  useEffect(() => {
    console.log("----> newPasswordRequired", newPasswordRequired)
    if(Object.keys(newPasswordRequired).length){
      setShowLogin(false);
      setShowRetrieve(false);
    }
  
    return () => {}
  }, [newPasswordRequired])
  

  if(storage.getItem('protagonistas_token')){
    return null;
  }

  return (
    <Wrapper>
      <BoxLogin>
        <Inner>

          <LogoContainer>
            <Logo />
          </LogoContainer>

          {showLogin &&
          <>
          <Login />
          {/* <ButtonForgot>
            <a
              onClick={() => handleShowRetrieve()}
            >
              <p>¿Olvidaste tu contraseña?</p>
            </a>
          </ButtonForgot> */}
          <div className='message_pass_change'>
            Si no podés ingresar envía un correo a contacto@somosprotagonistas.com.ar
            {/* Para resetear tu contraseña comunicate con el encargado de tu estación o enviá un mail a contacto@somosprotagonistas.com.ar */}
          </div>
          </>
          }

          {Object.keys(newPasswordRequired).length ?
            <CompleteUser 
              userAttributes={newPasswordRequired.challengeParam.userAttributes}//{name: "", family_name: "", email: "", gender: "", phone_number: "", birthdate: ""}
              userAuth={newPasswordRequired}
              setShowLogin={setShowLogin}
              setNewPasswordRequired={setNewPasswordRequired}
            />
          : "" }

          

          {showRetrieve &&
          <>
          <Retrieve />
          <ButtonForgot>
            <a
              onClick={() => handleShowBackLogin()}
            >
              <p>Volver al login</p>
            </a>
          </ButtonForgot>
          </>
          }

        </Inner>
      </BoxLogin>
          {/*<div className="hide">{window.navigator.platform}</div>*/}
    </Wrapper>
  )
}

export default LoginContainer;
