import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext, ContentContext } from '../../_context';
import CircularProgress from '@material-ui/core/CircularProgress';
import { breakpoints } from '../../_helpers/breakpoints';
import { incentivoTiendas, inventivo_tiendas_2 } from '../../_helpers/commons';
import storage from 'local-storage-fallback';
import { Auth } from 'aws-amplify';
import { ButtonYellow } from '~/_elements';

import axios from 'axios';

const Wrapper = styled.div`
    //position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /*
    height: 740px;
    background-size: auto;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    background-image: url(/images/top_incentivoautitos.png);
    */
    &.from_outside{
        background-image: url(/images/back_incentivoautitos4.jpg);
        background-repeat: repeat;
        background-attachment: local;
        background-size: cover;
        background-position-x: center;
    }
    /*
    setBackground({default: false,
                       type: 'image',
                       url: '/images/back_incentivoautitos4.jpg',
                       //url: '/images/background_challengebox.jpg',
                       repeat: 'repeat',
                       attachment: 'local',
                       size: 'cover'});
    */
    .autitos{
        //position: absolute;
    }
    
    .logos{
        display: flex;
        flex-direction: row;
        .select,
        .box,
        .shop{
            //width: 28vw;
            //height: 24vw;
            //padding-bottom: 3vw;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            //background-color: #FFC600;
            border-bottom-left-radius: 5vw;
            border-bottom-right-radius: 5vw;
            //margin-right: 3vw;
        }
        .select{
            .logo{
                width: 20vw;
                padding-bottom: 1.8vw;
                img{
                    float: left;
                    width: 100%;
                }
            }
        }
        .box{
            margin-top: 3vw;
            .logo{
                width: 27vw;
                img{
                    float: left;
                    width: 100%;
                }
            }
        }
        .shop{
            margin-right: 0;
            .logo{
                width: 20vw;
                padding-bottom: 2vw;
                img{
                    float: left;
                    width: 100%;
                }
            }
        }
    }
    .loading{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        svg{
            color: #FFC600;
        }
    }
    .box_autitos{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 50px;
        .autitos{
            width: 100%;
            padding-top: 60px;
            img{
                width: 100%;
                float: left;
            }
        }
    }
    >.inner{
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        .actions{
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            .bt_download_reglamento{
                width: 100%;
            }
            .bt_download_instructivo{
                width: 100%;
                margin-left: 0;
                margin-top: 10px;
            }
            .bt_download_transacciones{
                width: 100%;
                margin-left: 0;
                margin-top: 10px;
                line-height: 18px;
                padding-top: 9px!important;
                padding-bottom: 9px!important;
                height: auto;
            }
        }
        >.title{
            text-align: center;
            text-transform: uppercase;
            font-weight: 800;
            width: 100%;
            font-size: 6.5vw;
            line-height: 8vw;
            margin-bottom: 5.5vw;
            margin-top: 7vw;
            color: #FFFFFF;
            /* border-bottom: 1px solid #e9e8e8; */
            .brand{
                white-space: nowrap;
                color: #FFFFFF;//FFC600
            }
        }
        .data_station{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 100%;
            color: #FFFFFF;
            .station{
                display: flex;
                flex-direction: column;
                align-items: center;
                .label{
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 400;
                    text-transform: uppercase;
                }
                .value{
                    font-size: 40px;
                    line-height: 40px;
                    font-weight: 600;
                    margin-top: 5px;
                }
            }
            .name{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 30px;
                .label{
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 400;
                    text-transform: uppercase;
                }
                .value{
                    font-size: 40px;
                    line-height: 40px;
                    font-weight: 600;
                    margin-top: 5px;
                }
            }
            .select_boca{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 35px;
                width: 100%;
                label{
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 16px;
                }
                select{
                    width: 100%;
                    padding: 10px 10px;
                }
            }
        }
        .objective_title{
            font-size: 23px;
            line-height: 23px;
            font-weight: 800;
            text-transform: uppercase;
            margin-top: 30px;
            color: #FFFFFF;//FFC600
            border-bottom: 1px solid #FFFFFF;
            text-align: center;
        }
        .copy{
            max-width: 660px;
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-top: 30px;
            color: #FFFFFF;
            p{
                margin: 0;
                padding: 0;
                font-size: 16px;
                line-height: 20px;
            }
            .l1{
                
            }
            .l2,
            .l3{
                font-weight: 600;
                margin-top: 10px;
            }
            .l3{
                color: #FFC600;
            }
        }
        .box_objective{
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            width: 100%;
            ul, li{
                margin: 0;
                padding: 0;
                list-style: none;
            }
            ul{
                display: flex;
                flex-direction: column;
                li{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    /* background-color: aliceblue; */
                    background-color: rgba(255,255,255,0.95);
                    padding: 20px 30px;
                    margin-right: 0;
                    margin-top: 20px;
                    border-radius: 6px;
                    border: 1px solid #FFC600;
                    border-bottom: 5px solid #FFC600;

                    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
                    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
                    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
                    :last-child{
                        margin-right: 0;
                    }
                    .label{
                        text-transform: uppercase;
                        font-size: 12px;
                        line-height: 12px;
                        font-weight: 600;
                    }
                    .value{
                        font-size: 40px;
                        line-height: 40px;
                        font-weight: 800;
                        margin-top: 7px;
                        color: #FFC600;
                    }
                }
            }
        }
    }
    @media ${breakpoints.xs} {
        .box_autitos{
            display: flex;
            justify-content: center;
            width: 100%;
            .autitos{
                width: 80vw;
                padding-top: 60px;
                img{
                    width: 100%;
                    float: left;
                }
            }
        }
        .logos{
            display: flex;
            flex-direction: row;
            .select,
            .box,
            .shop{
                //width: 150px;
                //height: 130px;
                padding-bottom: 15px;
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 25px;
                //margin-right: 20px;
            }
            .select{
                .logo{
                    width: 100px;
                    padding-bottom: 5px;
                }
            }
            .box{
                margin-top: 15px;
                .logo{
                    width: 125px;
                    img{
                        float: left;
                        width: 100%;
                    }
                }
            }
            .shop{
                margin-right: 0;
                .logo{
                    width: 100px;
                    padding-bottom: 8px;
                }
            }
        }
        >.inner{
            width: 100%;
            .actions{
                width: 400px;
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                .bt_download_reglamento{
                    
                }
                .bt_download_instructivo{
                    margin-left: 0;
                    margin-top: 10px;
                }
                .bt_download_transacciones{
                    width: 100%;
                    margin-left: 0;
                    margin-top: 10px;

                    padding-top: unset!important;
                    padding-bottom: unset!important;
                    height: 40px;
                }
            }
            >.title{
                text-align: center;
                text-transform: uppercase;
                font-weight: 800;
                width: 100%;
                font-size: 40px;
                line-height: 45px;
                margin-bottom: 25px;
                margin-top: 35px;
                color: #FFFFFF;
                /* border-bottom: 1px solid #e9e8e8; */
                .brand{
                    white-space: nowrap;
                    color: #FFFFFF;//FFC600
                }
            }
            .box_objective{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-top: 30px;
                ul, li{
                    margin: 0;
                    padding: 0;
                    list-style: none;
                }
                ul{
                    display: flex;
                    flex-direction: row;
                    li{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        /* background-color: aliceblue; */
                        padding: 20px 30px;
                        margin-right: 20px;
                        margin-top: 0;
                        border-radius: 6px;
                        border: 1px solid #FFC600;
                        border-bottom: 5px solid #FFC600;

                        box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
                        -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
                        -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
                        :last-child{
                            margin-right: 0;
                        }
                        .label{
                            text-transform: uppercase;
                            font-size: 12px;
                            line-height: 12px;
                            font-weight: 600;
                        }
                        .value{
                            font-size: 40px;
                            line-height: 40px;
                            font-weight: 800;
                            margin-top: 7px;
                            color: #FFC600;
                        }
                    }
                }
            }
            .data_station{
                .select_boca{
                    margin-top: 35px;
                    width: 400px;
                    label{
                        text-transform: uppercase;
                        font-size: 16px;
                        line-height: 16px;
                    }
                    select{
                        width: 100%;
                        padding: 10px 10px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.sm} {
        /*
        >.inner{
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 0;
            background-color: skyblue;
            .actions{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 30px;
                .bt_download_reglamento{
                    width: 300px;
                }
                .bt_download_instructivo{
                    width: 300px;
                    margin-left: 10px;
                    margin-top: 0;
                }
                .bt_download_transacciones{
                    width: 380px;
                    margin-left: 10px;
                    margin-top: 0;
                }
            }
        }
        */
    }
    @media ${breakpoints.md} {
        >.inner{
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 0;
            .actions{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 30px;
                .bt_download_reglamento{
                    width: 170px;
                }
                .bt_download_instructivo{
                    width: 190px;
                    margin-left: 10px;
                    margin-top: 0;
                }
                .bt_download_transacciones{
                    width: 380px;
                    margin-left: 10px;
                    margin-top: 0;
                }
            }
        }
    }
    @media ${breakpoints.lg} {

    }
`

const IncentivoTiendas = props => {
    
    let history = useHistory();

    const { isAuth } = useContext(AuthContext);
    const { userData } = useContext(ContentContext);

    const [ metaData, setMetaData ] = useState(null);
    const [ dataStation, setDataStation ] = useState(null);
    const { search, pathname } = useLocation();
    const [ uid, setUid ] = useState(null);
    const [ token, setToken ] = useState(null);

    useEffect(() => {

        if(pathname){
            //console.log("pathname", pathname)
            if(pathname == '/incentivo-autitos'){
                if(isAuth !== null){
                    if(!isAuth){
                        if(search.length > 0) {
                            
                            const query = new URLSearchParams(search);
                            //console.log("query", query);
                            if(query.get("token") && query.get("token").length > 0){
                                setToken(query.get("token"));
                            }else{

                            }
                        }else{

                        }
                    }else{
                        getToken();
                    }
                }else{
                    if(search.length > 0) {
                            
                        const query = new URLSearchParams(search);
                        //console.log("query", query);
                        if(query.get("token") && query.get("token").length > 0){
                            setToken(query.get("token"));
                        }else{

                        }
                    }
                }
            }
        }
        return () => {}

    }, [pathname, isAuth])

    useEffect(() => {

        if(isAuth && userData){
            setUid(userData.dni);
        }
        return () => {}

    }, [isAuth, userData])

    const getData = async (token) => {
        //const requestURL = `http://localhost:8888/protagonitas_landings/incentivo_tiendas_2.php`;
        //const requestURL = `https://fabianhr.com/protagonistas/incentivo_tiendas_2.php`;
        const requestURL = 'https://v2-dev.1onesolutions.com/API/meta';

        try {
            //const params = new URLSearchParams()
            //params.append('type', "get_data");
            //params.append('uid', uid);

            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token//storage.getItem('protagonistas_access_token')
                }
            }
            //config.headers.Authorization = idToken.jwtToken;//temp_token;
            const result = await axios.get(requestURL, config);
            //console.log("result", result.data.single.api)
            setMetaData(result.data.single.meta);
            setDataStation(result.data.single.meta[0]);

            return result;

        } catch (error) {

        }
    }

    const handleChangeBoca = (e) => {
        
        const data = metaData.filter(el => el.boca === parseInt(e.target.value));
        //console.log(e.target.value, metaData, data);
        setDataStation(data[0]);
    }

    const getToken = async (config) => {

        await Auth.currentSession().then(res=>{
          const idToken = res.getIdToken();
          setToken(idToken.jwtToken);
          //return idToken.jwtToken;
          //config.headers.Authorization = idToken.jwtToken;
        })
      
    }

    const handleGoto = () => {

        history.push(`/campus/course/3:6733999cd89f8e3151bc883b`);
        //campus/course/3:6733999cd89f8e3151bc883b
    }

    const handleDownloadPDF = (pdf) => {

        switch (pdf) {
            case "reglamento":
                window.open('pdfs/Reglamento_Meta_Promo_Autitos_Coleccionables_2111_2024.pdf', '_blank').focus();
            break;
            case "instructivo":
                window.open('pdfs/Instructivo_Meta_Promo_Autitos_Coleccionables.pdf', '_blank').focus();
            break;
          default:
            break;
        }
    
    }

    /*
    useEffect(() => {
        
        if(uid){
            getData();
        }
    
      return () => {}
    }, [uid])
    */

    useEffect(() => {

        if(token){
            getData(token);
        }
    
      return () => {}
    }, [token])

    useEffect(() => {
        /*
        if(userData){
            if(userData.dni){
                
                if(!inventivo_tiendas_2.includes(userData?.dni)){
                    history.push('/');
                }
            }
        }
        */
        return () => {}
      }, [userData])
    
    if(!token){
        return (<>cargando</>)
    }

    return (
        <Wrapper 
            className={(isAuth === null || isAuth === false) ? "from_outside" : ""}
        >
            {(!metaData && !userData && !dataStation) ? 

                <div className='loading'>
                    <CircularProgress />
                </div>

                :
                <>
                <div className='inner'>
                    <div className='logos'>
                        {/* <div className='select'>
                            <div className='logo'>
                                <img src='images/logo_shell_select.svg' />
                            </div>
                        </div> */}
                        <div className='box'>
                            <div className='logo'>
                                <img src='images/logo_shellbox.svg' />
                            </div>
                        </div>
                        {/* <div className='shop'>
                            <div className='logo'>
                                <img src='images/Shell-SHOP.jpg' />
                            </div>
                        </div> */}
                    </div>
                    <div className='title'>
                        <span className='brand'>META PROMOCIÓN</span><br/>AUTITOS COLECCIONABLES
                    </div>
                    <div className='data_station'>
                        <div className='station'>
                            <div className='label'>Boca</div> 
                            <div className='value'>{dataStation?.boca}</div>
                        </div>
                        {/* <div className='name'>
                            <div className='label'>
                                Razon Social
                            </div>
                            <div className='value'>{dataStation?.razon}</div>
                        </div> */}
                        {metaData?.length > 1 ?
                        <div className='select_boca'>
                            <label>Seleccionar boca</label>
                            <select id="" onChange={handleChangeBoca}>
                                {metaData.map((boca, index) => {
                                    return(
                                        <option value={boca.boca}>{`${boca.boca} - ${boca?.razon_social}`}</option>
                                    )
                                })}
                            </select>
                        </div>
                        : ""}
                    </div>
                    <div className='objective_title'>
                        Resultados
                    </div>
                    <div className='box_objective'>
                        <ul>
                            <li>
                                <div className='label'>
                                    Autitos Recibidos
                                </div>
                                <div className='value'>
                                    {dataStation?.stock_autitos ? dataStation.stock_autitos : '---'}
                                </div>
                            </li>
                            <li>
                                <div className='label'>
                                    Autitos Vendidos
                                </div>
                                <div className='value'>
                                    {dataStation?.autitos_vendidos ? dataStation.autitos_vendidos : '---'}
                                </div>
                            </li>
                            <li>
                                <div className='label'>
                                    % Objetivo
                                </div>
                                <div className='value'>
                                    {dataStation?.porcentaje_stock ? dataStation.porcentaje_stock.toFixed(1) : '---'}
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className='copy'>
                        <p className='l1'>Las estaciones competirán en distintas ligas según la cantidad de autitos recibidos: Liga A: a partir de 600 autitos / Liga B: de 264 a 576 autitos / Liga C: de 48 a 240 autitos /Liga Estaciones Propias.</p>
                        <p className='l2'>Vigencia desde el 4 de noviembre 2024 al 12 de enero 2025.</p>
                        <p className='l2'>Los resultados se actualizarán cada 24 a 48hs. hábiles.</p>
                        <p className='l3'>Información actualizada hasta el 11/12 inclusive.</p>
                    </div>
                    
                    <div className='actions'>
                        <ButtonYellow
                            className="bt_download_reglamento"
                            onClick={() => handleDownloadPDF("reglamento")}
                            disabled={false}
                            css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                            >
                            <p>REGLAMENTO</p>
                        </ButtonYellow>

                        <ButtonYellow
                            className="bt_download_instructivo"
                            onClick={() => handleDownloadPDF("instructivo")}
                            disabled={false}
                            css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                            >
                            <p>INSTRUCTIVO META</p>
                        </ButtonYellow>

                        <ButtonYellow
                            className="bt_download_transacciones"
                            onClick={() => handleGoto()}
                            disabled={false}
                            css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                            >
                            <p>¿CÓMO IDENTIFICAR TUS TRANSACCIONES?</p>
                        </ButtonYellow>
                    </div>

                </div>
                <div className='box_autitos'>
                    <div className='autitos'>
                        <img src='/images/autitos1.png' />
                    </div>
                </div>
                </>
            }
        </Wrapper>
    );
};

export default IncentivoTiendas;