import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';
import { Spinner } from '../../_helpers/constants';
import { AuthContext } from '../../_context/AuthContext';
import { AiFillEyeInvisible,
         AiFillEye } from "react-icons/ai";
import { breakpoints } from '~/_helpers/breakpoints';
import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';
import { RiCloseCircleLine } from "react-icons/ri";

import moment from 'moment';
import DateFnsUtils from '@date-io/moment';
import "moment/locale/es";
moment.locale('es');

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .session_timeout{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    .inner{
      padding: 20px;
      width: calc(100% - 50px);
      background-color: rgba(255, 255, 255, 1);
      -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      -ms-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      border-radius: 12px
    }
  }
  .box_error{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(255,255,255, 0.85);
    width: 100%;
    height: 100%;
    z-index: 999;
    .box_error_inner{
      width: calc(100% - 50px);
      border: 1px solid #ececec;
      border-radius: 6px;
      padding: 10px 10px;
      background-color: #FFFFFF;
      -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      -ms-box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      .title{
        font-weight: 500;
        //color: red;
        padding-bottom: 7px;
        margin-bottom: 12px;
        border-bottom: 1px solid #ececec;
      }
      .messages{
        p{
          color: red;
          font-size: 15px;
          line-height: 18px;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  .single{
    display: flex;
    flex-direction: column;
    width: 100%;
    &.fecha_nacimiento{
      //margin-top: 5px;
      >label{
        margin-bottom: 5px!important;
      }
    }
  }
  .double{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .field{
      width: calc(50% - 7px);
      
      .field_phone{
        display: flex;
        flex-direction: row;
        .prefix{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70px;
          font-size: 15px;
          line-height: 15px;
          font-weight: 600;
          background-color: #edeef0;
        }
      }
    }
  }
  .tiple{
    display: flex;
    flex-direction: row;
  }
  .field{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    &.field_password{
      margin-top: 15px;
      position: relative;
      .password_show{
        width: 50px;
        right: 0;
        top: 21px;
        text-align: center;
        position: absolute;
        font-size: 30px;
        line-height: 41px;
        padding-bottom: 1px;
        cursor: pointer;
        :hover{
          color: #FFD200;
        }
      }
    }
    >label{
      display: flex;
      width: 100%;
      margin-bottom: 1px;
      color: #242424;
    }
    input,
    select{
      width: 100%;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 18px;
      border: 1px solid #edeef0;
      
      ::placeholder {
        color: #a8a8a8;
        font-size: 16px;
      }
      :focus{
        outline: none;
      }
      *:focus {
        outline: none;
      }
      &.error{
        border: 1px solid red!important;
      }
    }
    &.dia,
    &.mes,
    &.ano{
      width: calc(33% - 5px);
    }
    
    small{
      color: #797979;
      font-size: 13px;
      line-height: 17px;
      margin-top: 5px;
    }
  }
  .nacimiento{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    label{
      font-size: 13px;
      line-height: 13px;
      margin-bottom: 5px;
    }
  }
`
const ModalStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  &.scrolleable{
    position: absolute;
    overflow: scroll;
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .inner_modal_contact_message{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* width: 100vw;
    height: 100vh; */
    width: calc(100% - 30px);
    height: auto;
    padding: 5vw;
    background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 16px;
    .box_modal_validations{
      width: 100%;//calc(100% - 50px);
      .box_goto{
        margin-top: 20px;
        >p{
          width: 100%;
          text-align: center;
          margin: 0;
          padding: 0;
          margin-bottom: 5px;
        }
        .bt_send_continuar{

        }
      }
      .bt_send_code{
        margin-top: 10px;
      }
      input{
        padding: 7px 10px;
        font-size: 18px;
        width: 100%;
        border: 1px solid #edeef0;
        &.error{
          border: 1px solid red!important;
        }
      }
    }
    .box_email_verify,
    .box_phone_verify{
      .loader{
        width: 40px;
        height: 40px;
        margin-top: 10px!important;
      }
      .virified{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        color: #FFD200;
        height: 40px;
        /* margin-top: 10px; */
        border: 1px dashed #FFD200;
      }
    }
    .box_verify_separa{
      display: flex;
      width: 100%;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .errorMessages{
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
      border: 1px dashed #F20000;
      background-color: #FF9494;
      color: #000000;
      padding: 6px 10px 6px 10px;
      font-size: 15px;
      line-height: 19px;
      p{

      }
    }
    .successMessages{
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
      background-color: #B8FF6D;
      //#F7DECC,#AD8D75,#B8FF6D,#1B110A,#E0C0A8,#F7DECC,#AD8D75,#B8FF6D
      border: 1px dashed #33E46D;
      color: #000000;
      padding: 6px 10px 6px 10px;
      font-size: 15px;
      line-height: 19px;
    }

    .title{
      width: 80vw;
      margin-top: 0;
      //margin-bottom: 6vw;
      p{
        text-align: left;
        font-size: 7vw;
        line-height: 8.5vw;
        width: 100%;
      }
    }

    .contact_form{
      display: flex;
      flex-direction: column;
      width: 100%;

      .field_channel{
        margin-bottom: 20px;
      }
      .field_subject{
        margin-bottom: 20px;
      }
      .field_message{
        margin-bottom: 10px;
      }
      .modal_actions{
        .loader{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 250px;
          height: 40px;
          //margin-top: 6px;
          svg{
            color: #FFD200;
          }
        }
      }

      .is_invalid{
        border: 1px solid red!important;
      }
      .fix_is_invalid{
        border: 0;
      }

      .field{
        label{
          display: flex;
          width: 100%;
          font-size: 16px;
          line-height: 16px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 7px;
        }
        input{
          display: flex;
          padding: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          width: 100%;
        }
        textarea{
          display: flex;
          padding: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          width: 100%;
        }
        select{
          display: flex;
          padding: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          width: 100%;
        }
      }
    }
  }

  &.scrolleable{
    padding-top: 0;
    padding-bottom: 0;
  }

  @media ${breakpoints.xs} {
    
    .inner_modal_contact_message{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 30px);
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;

      .title{
        width: calc(100% - 40px);
        margin-top: 0;
        margin-bottom: 0;
        p{
          text-align: left;
          font-size: 37px;
          line-height: 40px;
          width: 100%;
        }
      }

    }
  }
  @media ${breakpoints.sm} {
    &.scrolleable{
      padding-top: 20px;
      padding-bottom: 20px;
    }
    
    .inner_modal_contact_message{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 700px;
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;

      .title{
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        p{
          text-align: left;
          font-size: 37px;
          line-height: 40px;
          width: 100%;
        }
      }

    }
  }
  @media ${breakpoints.md} {
    
  }
  @media ${breakpoints.lg} {

  }
`
const ButtonClose = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  cursor: pointer;
  right: 20px;
  top: 20px;
  z-index: 9;
  .icon{
    display: flex;
    font-size: 45px;
    color: #FFD200;
  }
  :hover{
    .icon{
      color: #000000;
    }
  }
`
const Input = styled.input`
  
`
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .loader{
    margin-top: 15px!important;
    height: 40px;
  }
`
const ErrorMessage = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3px;
  p{
    color: #E02020;
    width: 100%;
    font-size: 14px;
    text-align: right;
  }
`

const CompleteUser = (props) => {

  const { userAttributes,
          userAuth,
          setShowLogin } = props;

  const { Auth,
          login,
          isAuthLoading,
          setIsAuthLoading,
          loginError,
          setLoginError,
          signIn,
          setNewPasswordRequired } = useContext(AuthContext);

  const [ user, setUser ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ repeatPassword, setRepeatPassword ] = useState("");
  const [ passwordShow, setPasswordShow ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ birthDateDay, setBirthDateDay ] = useState("");
  const [ birthDateMonth, setBirthDateMonth ] = useState("");
  const [ birthDateYear, setBirthDateYear ] = useState("");
  const [ yearSelect, setYearSelect ] = useState(null);
  const [ gender, setGender ] = useState("");
  
  const [ firstName, setFirstName ] = useState("");
  const [ lastName, setLastName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phone, setPhone ] = useState("");

  const [ emailOld, setEmailOld ] = useState("");
  const [ phoneOld, setPhoneOld ] = useState("");

  const [ formError, setFormError ] = useState([]);
  const [ formErrorMessages, setFormErrorMessages ] = useState([]);
  const [ repeatPasswordShow, setRepeatPasswordShow ] = useState(false);
  const [ openModalCode, setOpenModalCode ] = useState(false);
  const [ verificationCode, setVerificationCode ] = useState("");
  const [ verificationPhoneCode, setVerificationPhoneCode ] = useState("");
  const [ reValidateScope, setReValidateScope ] = useState([]);
  const [ loadingValidatePhone, setLoadingValidatePhone ] = useState(false); 
  const [ loadingValidateEmail, setLoadingValidateEmail ] = useState(false);
  const [ isEmailVerificationPending, setIsEmailVerificationPending ] = useState(true);
  const [ phoneIsVerificationPending, setPhoneIsVerificationPending ] = useState(true);

  const [ verificationPhoneNumberCodeError, setVerificationPhoneNumberCodeError ] = useState(false);
  const [ verificationEmailCodeError, setVerificationEmailCodeError ] = useState(false);

  const [ verifyOk, setVerifyOk ] = useState([]);
  const [ sessionTimeout, setSessionTimeout ] = useState(false);
 

  const [ errorFields, setErrorFields ] = useState({user: false, password: false});

  const days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const yearLimit = moment().subtract(18, 'years').year();

  const [ haveError, setHaveError ] = useState({
    first_name: { status: false, message: "" },
    last_name: { status: false, message: "" },
    email: { status: false, message: "" },
    phone: { status: false, message: "" },
    birth_date: { status: false, message: "" },
  });

  const handleContinue = () => {

    setLoading(false);
    setLoginError(false);
    setShowLogin(true);
    setNewPasswordRequired({});
    setIsAuthLoading(false);

  }

  const handleCheckChange = () => {
    
    setVerificationCode("");
    setVerificationPhoneCode("");
    setLoadingValidateEmail(false);
    setLoadingValidatePhone(false);

    setIsEmailVerificationPending(true);
    setPhoneIsVerificationPending(true);

    setVerificationPhoneNumberCodeError(false);
    setVerificationEmailCodeError(false);

    let revalidate_scopes = [];

    //revalidate_scopes.push("phone");
    if(phone !== phoneOld){
      revalidate_scopes.push("phone");
    }

    if(email !== emailOld){
      revalidate_scopes.push("email");
    }
    
    if(revalidate_scopes.length){
      setReValidateScope(revalidate_scopes);
      handleCloseModalCode();
      return true;
    }

    return false;

  }

  const handleFormSubmit = async () => {

    let formErrorMessagesArr = [...formErrorMessages];

    if(!firstName.length || !lastName.length || !gender.length || !email.length || !phone.length || !password.length || !repeatPassword.length || !birthDateDay.length || !birthDateMonth.length || !birthDateYear.length){
      formErrorMessagesArr.push("- Todos los campos son obligatorios.");
    }else{
      if(!validateEmail(email)){
        formErrorMessagesArr.push("- El Email que ingresaste es incorrecto, recordá que debe contener @ y .");
      }
      if (password !== repeatPassword) {
        formErrorMessagesArr.push("- Las contraseñas no coinciden.");
      }
      if (password.length) {
        if(!validarPass(password)){
          formErrorMessagesArr.push("- La contraseña debe tener al menos 8 caracteres, al menos una letra mayúscula y al menos un carácter especial.");
        }
      }
    }

    if(formErrorMessagesArr.length){
      setFormErrorMessages(formErrorMessagesArr);
    }else{
      try {
          console.log("TRY")
          setLoading(true);
          let update_pass = await Auth.completeNewPassword(userAuth, password);
          console.log("update_pass", update_pass)
          const updatedAttributes = {}
          updatedAttributes.name = firstName
          updatedAttributes.family_name = lastName
          updatedAttributes.birthdate = birthDateYear + "-" + birthDateMonth + "-" + birthDateDay;
          updatedAttributes.gender = gender
          updatedAttributes.phone_number = '+549' + phone
          updatedAttributes.email = email
          let update = await Auth.updateUserAttributes(update_pass, updatedAttributes);
          console.log("update", update);
          const check_change = handleCheckChange();
          if(!check_change){
            setLoading(false);
            setLoginError(false);
            setShowLogin(true);
            setNewPasswordRequired({});
          }
          //return null;

          
      } catch (error) {
          console.log("CATCH")
          setLoading(false);
          const errorMessage = error.message || error.toString()
          if (errorMessage.includes('Invalid session for the user, session is expired')) {
            setSessionTimeout(true);
          } else {
            let formErrorMessagesArr = [...formErrorMessages];
            formErrorMessagesArr.push("- Ha ocurrido un error al intentar cambiar la contraseña.");
            setFormErrorMessages(formErrorMessagesArr);
          }
      }
    }

  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const validarPass = (pass) => {
    // Verificar longitud de la contraseña
    if (pass.length < 8) {
        return false
    }

    // Verificar que tenga al menos una mayúscula
    const mayusculaRegex = /[A-Z]/
    if (!mayusculaRegex.test(pass)) {
        return false
    }

    // Verificar que tenga al menos un carácter especial
    const caracterEspecialRegex = /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/
    if (!caracterEspecialRegex.test(pass)) {
        return false
    }

    // Si pasa todas las verificaciones, es válida
    return true
}

  const fillSelect = () => {
    let items = [];
    for (let year = yearLimit; year > 1900; year--) {
      items.push(year);
    }
    setYearSelect(items);
  }

  const handleCloseError = () => {
    setFormErrorMessages([]);
  }

  const handleCloseModalCode = () => {
    setOpenModalCode(!openModalCode);
  }
  
  const verifyEmailCode = async () => {
    setVerificationEmailCodeError(false);
    if(verificationCode.length){
      //return null;
      try {
          setLoadingValidateEmail(true);
          await Auth.verifyCurrentUserAttributeSubmit('email', verificationCode);
          setIsEmailVerificationPending(false);
          setLoadingValidateEmail(false);

          if(!verifyOk.includes("email")){
            let verifyOk_update = [...verifyOk];
            verifyOk_update.push("email");
            setVerifyOk(verifyOk_update);
          }
          
          console.log("Email verificado correctamente");
          //setIsEmailVerificationPending(false)
          //setShowCangedPassword(true);
          //showLogin();
      } catch (verificationError) {
          setLoadingValidateEmail(false);
          console.error("Error verificando el email:", verificationError.message);
          //Alert.alert('Error', 'El código de verificación del correo electrónico es incorrecto.');
      }
    }else{
      setVerificationEmailCodeError(true);
    }
};

  const verifyPhoneNumberCode = async () => {
      setVerificationPhoneNumberCodeError(false);
      if(verificationPhoneCode.length){
        //return null;
        try {
            setLoadingValidatePhone(true);
            await Auth.verifyCurrentUserAttributeSubmit('phone_number', verificationPhoneCode);
            setPhoneIsVerificationPending(false);
            setLoadingValidatePhone(false);

            if(!verifyOk.includes("phone")){
              let verifyOk_update = [...verifyOk];
              verifyOk_update.push("phone");
              setVerifyOk(verifyOk_update);
            }

            console.log("Número de teléfono verificado correctamente");
            //setPhoneIsVerificationPending(false);
            //setShowCangedPassword(true);
            //showLogin();
        } catch (verificationError) {
            setLoadingValidatePhone(false);
            console.error("Error verificando el teléfono:", verificationError.message);
            //Alert.alert('Error', 'El código de verificación del número de teléfono es incorrecto.');
        }
      }else{
        setVerificationPhoneNumberCodeError(true);
      }
  };

  useEffect(() => {
    
    setIsAuthLoading(false);
    fillSelect();
  
    return () => {}
  }, [])
  
  useEffect(() => {

    console.log("userAttributes", userAttributes)
    if(userAttributes){

      if("name" in userAttributes){
        setFirstName(userAttributes.name);
      }
      if("family_name" in userAttributes){
        setLastName(userAttributes.family_name);
      }
      if("email" in userAttributes){
        setEmail(userAttributes.email);
        setEmailOld(userAttributes.email);
      }
      if("gender" in userAttributes){
        setGender(userAttributes.gender);
      }

      const prefix = '+549';

      if("phone_number" in userAttributes){
        if(userAttributes.phone_number.startsWith(prefix)){
          setPhone(userAttributes.phone_number.substr(prefix.length));
          setPhoneOld(userAttributes.phone_number.substr(prefix.length));
        }else{
          setPhone(userAttributes.phone_number);
          setPhoneOld(userAttributes.phone_number);
        }
      }

      if("birthdate" in userAttributes){
        if(userAttributes.birthdate){
          const birthdayFormat = moment(userAttributes.birthdate).format("yyyy-MM-DD");
      
          setBirthDateDay(userAttributes.birthdate.split('-')[2].substring(0,2));
          setBirthDateMonth(userAttributes.birthdate.split('-')[1]);
          setBirthDateYear(userAttributes.birthdate.split('-')[0]);
        }
      }

    }
    return () => {}
  }, [userAttributes])
  
  useEffect(() => {
    console.log("reValidateScope", reValidateScope)
    
    return () => { }
  }, [reValidateScope])
  
  const handleGotoLogin = () => {
    setLoginError(false);
    setShowLogin(true);
    setNewPasswordRequired({});
  }

  useEffect(() => {
    console.log("email, emailOld", email, emailOld)
  
    return () => {}
  }, [email, emailOld])
  
  
  return (
    <Wrapper>
      {sessionTimeout ? 
      <div className='session_timeout'>
        <div className='inner'>
          El tiempo de sesión finalizó, para cambiar tu contraseña volve a ingresar con tu dni y contraseña temporal
          <ButtonYellow className="bt_login" onClick={handleGotoLogin}>
            <p>CONTINUAR</p>
          </ButtonYellow>
        </div>
      </div> : "" }

      <ModalStyled
          open={openModalCode}
          //onClose={handleCloseModalCode}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
          <Fade in={openModalCode}>
              <div className="inner_modal_contact_message">
                  {/* <ButtonClose onClick={() => handleCloseModalCode()}>
                    <div className="icon">
                        <RiCloseCircleLine />
                    </div>
                  </ButtonClose> */}
                  <div className='box_modal_validations'>
                    {reValidateScope.includes("phone") ? 
                      <div className='box_phone_verify'>
                        <label>{`Para actualizar tu número de teléfono ingresa el código que te enviamos vía SMS a ${phone}:`}</label>
                        {phoneIsVerificationPending ?
                        <input 
                          className={`${verificationPhoneNumberCodeError ? ' error' : ''}`}
                          type="text" 
                          onChange={(e) => setVerificationPhoneCode(e.target.value)} 
                          value={verificationPhoneCode}
                          placeholder='Ingresá el código...'
                        /> : "" }
                        {loadingValidatePhone ? 
                          <Spinner className="loader" />
                        :
                          phoneIsVerificationPending ?
                          <ButtonYellow className="bt_send_code" onClick={verifyPhoneNumberCode}>
                            <p>VERIFICAR</p>
                          </ButtonYellow>
                          : <div className='virified'>Verificado</div>
                        }
                      </div>
                    : "" }

                    {reValidateScope.length > 1 ?
                    <div className='box_verify_separa'></div>
                    : "" }
                    
                    {reValidateScope.includes("email") ? 
                      <div className='box_email_verify'>
                        <label>{`Para actualizar tu E-Mail ingresá el código que te enviamos a ${email}:`}</label>
                        {isEmailVerificationPending ?
                        <input 
                          className={`${verificationEmailCodeError ? ' error' : ''}`}
                          type="text" 
                          onChange={(e) => setVerificationCode(e.target.value)} 
                          value={verificationCode}
                          placeholder='Ingresá el código...'
                        /> : "" }
                        {loadingValidateEmail ? 
                          <Spinner className="loader" />
                        :
                          isEmailVerificationPending ?
                          <ButtonYellow className="bt_send_code" onClick={verifyEmailCode}>
                            <p>VERIFICAR</p>
                          </ButtonYellow>
                          : <div className='virified'>Verificado</div>
                        }
                      </div>
                    : "" }

                    {verifyOk.length === reValidateScope.length ?
                    <div className='box_goto'>
                      <p>Por favor, hacé click en continuar, para ingresar con tu nueva contraseña.</p>
                      <ButtonYellow className="bt_send_continuar" onClick={handleContinue}>
                        <p>CONTINUAR</p>
                      </ButtonYellow>
                    </div>
                    : "" }

                  </div>
              </div>
          </Fade>
      </ModalStyled>

      {formErrorMessages.length ? 
      <div className='box_error'>
        <div className='box_error_inner'>
          <div className='title'>Para continuar corrija los siguietes errores</div>
          <div className='messages'>
            {formErrorMessages.map((message, index) => {
              return(
                <p>{ message }</p>
              )
            })}
          </div>
          <ButtonYellow className="bt_login" onClick={handleCloseError}>
            <p>CERRAR</p>
          </ButtonYellow>
        </div>
      </div> : "" }

      <div className='double'>
        <div className='field'>
          <label>Nombre *</label>
          <input
            type="text"
            placeholder="Ingresá tu Nombre..."
            value={firstName}
            className={errorFields.user ? 'error' : '' }
            onChange={(e) => setFirstName(e.currentTarget.value)}
          />
        </div>

        <div className='field'>
          <label>Apellido *</label>
          <input
            type="text"
            placeholder="Ingresá tu Apellido..."
            value={lastName}
            className={errorFields.user ? 'error' : '' }
            onChange={(e) => setLastName(e.currentTarget.value)}
          />
        </div>
      </div>

      <div className='single fecha_nacimiento'>
        <label>Fecha de Nacimiento *</label>
        <div className='nacimiento'>
          <div className='field dia'>
            <label>Dia *</label>
            <select
              value={birthDateDay == null ? "" : birthDateDay}
              onChange={(e) => { console.log("e.target.value", e.target.value); setBirthDateDay(e.target.value); } }
              className={haveError.birth_date.status ? "is_invalid" : ""}
            >
              <option value="">Día...</option>
              {
                days.map((day, index) => {
                  return(
                    <option vlaue={day}>{day}</option>
                  )
                })
              }
            </select>
          </div>
          <div className='field mes'>
            <label>Mes *</label>
            <select
              value={birthDateMonth == null ? "" : birthDateMonth}
              onChange={(e) => { setBirthDateMonth(e.target.value); }}
              className={haveError.birth_date.status ? "is_invalid" : ""}
            >
              <option value="">Mes...</option>
              {
                months.map((month, index) => {
                  return(
                    <option vlaue={month}>{month}</option>
                  )
                })
              }
            </select>
          </div>
          <div className='field ano'>
            <label>Año *</label>
            <select
              value={birthDateYear == null ? "" : birthDateYear}
              onChange={(e) => { setBirthDateYear(e.target.value); }}
              className={haveError.birth_date.status ? "is_invalid" : ""}
            >
              <option value="">Año...</option>
              {yearSelect && yearSelect.map((year, index) => {
                return <option key={index} value={year}>{year}</option>
              })}
            </select>
          </div>
        </div>
      </div>

      <div className='single'>
        <div className='field'>
          <label>Género *</label>
          <select
            type="text"
            placeholder="Ingresá tu Género..."
            value={gender}
            className={errorFields.user ? 'error' : '' }
            onChange={(e) => setGender(e.currentTarget.value)}
          >
            <option value="">Selecciona una opción...</option>
            <option value="Mujer">Femenino</option>
            <option value="Hombre">Masculino</option>
            <option value="X">X</option>
          </select>
        </div>
      </div>

      <div className='double'>
        <div className='field'>
          <label>Celular *</label>
          <div className='field_phone'>
            <div className='prefix'>+549</div>
            <input
              type="text"
              placeholder="Ingresá tu Celular..."
              value={phone}
              className={errorFields.user ? 'error' : '' }
              onChange={(e) => setPhone(e.currentTarget.value)}
            />
          </div>
        </div>

        <div className='field'>
          <label>Email *</label>
          <input
            type="text"
            placeholder="Ingresá tu Email..."
            value={email}
            className={errorFields.user ? 'error' : '' }
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </div>
      </div>
        
      <div className='single'>
        <div className='field field_password'>
          <label>Contraseña *</label>
          <input
            type={passwordShow ? 'text' : 'password'}
            placeholder="Contraseña..."
            value={password}
            className={errorFields.user ? 'error' : '' }
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <div className="password_show" onClick={() => setPasswordShow(!passwordShow)}>
            { passwordShow ? <AiFillEyeInvisible /> : <AiFillEye /> }
          </div>
          <small>* Debe contener más de 8 carácteres<br/>* Debe contener al menos 1 letra mayúscula<br/>* Debe contener al menos 1 carácter extraño (ej: $!#?¡¿)</small>
        </div>
      </div>

      <div className='single'>
        <div className='field field_password'>
          <label>Confirman Contraseña *</label>
          <input
            type={repeatPasswordShow ? 'text' : 'password'}
            placeholder="Confirman Contraseña..."
            value={repeatPassword}
            className={errorFields.user ? 'error' : '' }
            onChange={(e) => setRepeatPassword(e.currentTarget.value)}
          />
          <div className="password_show" onClick={() => setRepeatPasswordShow(!repeatPasswordShow)}>
            { repeatPasswordShow ? <AiFillEyeInvisible /> : <AiFillEye /> }
          </div>
        </div>
      </div>

      {/* <Field className="field_password">
        <Label>Apellido:</Label>
        <Input
          type={passwordShow ? 'text' : 'password'}
          placeholder="Ingresá tu Contraseña."
          value={password}
          className={errorFields.password ? 'error' : '' }
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        <div className="password_show" onClick={() => setPasswordShow(!passwordShow)}>
            { passwordShow ? <AiFillEyeInvisible /> : <AiFillEye /> }
        </div>
      </Field> */}

      {/* {formError &&
        <ErrorMessage>
          <p>Hay un error en el D.N.I o contraseña ingresada.</p>
        </ErrorMessage>
      } */}

      <Actions>

        {loading ?
        <Spinner className="loader" />
        :
        <ButtonYellow className="bt_login" onClick={handleFormSubmit/*handleCheckChange*/}>
          <p>ENVIAR</p>
        </ButtonYellow>
        }

      </Actions>

    </Wrapper>
  )
}

export default CompleteUser;
