import React, { useState, useRef, useCallback, useContext } from 'react';
import { path } from 'ramda';
import _connect from '~/_helpers/new_connect';
import storage from 'local-storage-fallback';
import { AuthContext } from './index';

export const CommentContext = React.createContext({});

export const CommentProvider = ({ children }) => {
//export const CommentProvider = props => {

  const { logout } = useContext(AuthContext);

  const page = useRef(1);
  const [comments, setComments] = useState({
    list: [],
    hasMore: true,
  });
  const [loading, setLoading] = useState(false);
  const [refetching, setRefetching] = useState(false);

  const mergeComments = (prevComments, newComments) => {
    return [...prevComments, ...newComments];
  };

  const addNewComment = (prevComments, newComment) => {
    return [newComment, ...prevComments];
  };

  const getComments = async (postId) => {
    setLoading(true);

    try{
      const token = storage.getItem('protagonistas_token');
      const response = await _connect.get(`${process.env.REACT_APP_API_SERVER}/wall/get_post_comment`, {
        headers: {
          'token': token,
          'id_post': postId,
        },
      });

      const newComments = path(['data', 'single'], response) || [];
      const lastPage = path(['data', 'last_page'], response) || [];

      page.current += 1;

      setComments({
        list: newComments,
        hasMore: false,
      });


      /*
      setComments(prev => ({
        ...prev,
        list: mergeComments(prev.list, newComments),
        hasMore: lastPage >= page.current,
      }));
      */

      setLoading(false);

    }catch(error){
      logout();
    }
  }

  const postComment = async comment => {
    try {

      const token = storage.getItem('protagonistas_token');

      const payload = { id_post: comment.id_post, text: comment.text };

      const params = new URLSearchParams()
            params.append('id_post', payload.id_post)
            params.append('text', payload.text)
            params.append('token', token)

            const config = {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }

      const result = await _connect.post(`${process.env.REACT_APP_API_SERVER}/wall/created_comment`, params, config);

    } catch (error) {
      logout();
    }
  };

  const refetchComments = useCallback(
    async postId => {

      setLoading(true);
      setRefetching(true);

      try {

        const token = storage.getItem('protagonistas_token');
        const response = await _connect.get(`${process.env.REACT_APP_API_SERVER}/wall/get_post_comment`, {
          headers: {
            'token': token,
            'id_post': postId,
          },
        });

        const newComments = path(['data', 'single'], response) || [];
        const lastPage = path(['data', 'last_page'], response) || [];

        page.current = 2;

        setComments({
          list: newComments,
          hasMore: false,
        });
        /*
        setComments(() => ({
          list: newComments,
          hasMore: lastPage >= page.current,
        }));
        */

        setLoading(false);
        setRefetching(false);

      } catch (err) {
        logout();
        setLoading(false);
        setRefetching(false);
      }

    },
    [],//addToCommentsTotal
  );


  const onSubmitComment = useCallback(async postId => refetchComments(postId), [refetchComments]);

  return (
    <CommentContext.Provider
      value={{
        getComments,
        postComment,
        comments,
        loading,
        onSubmitComment,
        refetching,
      }}>
      <div>{children}</div>
    </CommentContext.Provider>
  );
};
